<template>
  <div class="w-full h-full max-h-screen overflow-auto flex flex-col justify-center items-center gap-3 py-2">
    <div class="w-11/12 flex justify-between items-center px-10 py-4 bg-slate-400">
      <div>
        <!-- <a href="../../public/Material_Template.xlsx" class="bg-slate-200 px-2 py-1 mb-3" download="Material_Template.xlsx">Download Template</a> -->
        <h1 class="text-2xl font-bold">Import Raw Data</h1>
        <div class="flex flex-col gap-2">
          <input type="file" @change="handleFileUpload" />
          <button @click="saveMaterialToDatabase" v-if="excelData.length > 0" class="px-2 py-1 bg-green-600 text-white" :disabled="saveClicked">Simpan ke Database</button>
        </div>
      </div>
      <div>
        <button @click="exportToExcel" class="px-2 py-1 bg-green-600 text-white">Export to Excel</button>
        <p>{{  }}</p>
      </div>
    </div>
  
    <div class="w-11/12 flex flex-wrap justify-center gap-2 px-10 py-4 bg-slate-400">
      <div class="w-1/4 flex justify-center bg-slate-200">
        <div class="w-full flex flex-col gap-7 items-center justify-center py-5 px-3 h-52">
          <h1 class="text-2xl text-center font-semibold">Attribute</h1>
          <div class="flex flex-col justify-center items-center gap-2 text-sm">
            <button class="min-w-[100px] py-1 bg-orange-400" @click="viewAttribute">View</button>
            <button class="min-w-[100px] py-1 bg-green-400" @click="uploadAttribute">Upload Excel</button>
            <button class="min-w-[100px] py-1 bg-blue-400" @click="insertAttribute">Insert</button>
          </div>
        </div>
      </div>
      <div class="w-1/4 flex justify-center bg-slate-200">
        <div class="flex flex-col gap-7 items-center justify-center py-5 px-3 h-52">
          <h1 class="text-2xl text-center font-semibold">Global Attribute</h1>
          <div class="flex flex-col justify-center items-center gap-2 text-sm">
            <button class="min-w-[100px] py-1 bg-orange-400" @click="viewGlobal">View</button>
            <button class="min-w-[100px] py-1 bg-green-400" @click="uploadGlobal">Upload Excel</button>
            <button class="min-w-[100px] py-1 bg-blue-400" @click="insertGlobal">Insert</button>
          </div>
        </div>
      </div>
      <div class="w-1/4 flex justify-center bg-slate-200">
      <div class="flex flex-col gap-7 items-center justify-center py-5 px-3 h-52">
          <h1 class="text-2xl text-center font-semibold">INC</h1>
          <div class="flex flex-col justify-center items-center gap-2 text-sm">
            <button class="min-w-[100px] py-1 bg-orange-400" @click="viewInc">View</button>
            <button class="min-w-[100px] py-1 bg-green-400" @click="uploadInc">Upload Excel</button>
            <button class="min-w-[100px] py-1 bg-blue-400" @click="insertInc">Insert</button>
          </div>
        </div>
      </div>
      <div class="w-1/4 flex justify-center bg-slate-200">
        <div class="flex flex-col gap-7 items-center justify-center py-5 px-3 h-52">
          <h1 class="text-2xl text-center font-semibold">Group</h1>
          <div class="flex flex-col justify-center items-center gap-2 text-sm">
            <button class="min-w-[100px] py-1 bg-orange-400" @click="viewGroup">View</button>
            <button class="min-w-[100px] py-1 bg-green-400" @click="uploadGroup">Upload Excel</button>
            <button class="min-w-[100px] py-1 bg-blue-400" @click="insertGroup">Insert</button>
          </div>
        </div>
      </div>
      <div class="w-1/4 flex justify-center bg-slate-200">
        <div class="flex flex-col gap-7 items-center justify-center py-5 px-3 h-52">
          <h1 class="text-2xl text-center font-semibold">User</h1>
          <div class="flex flex-col justify-center items-center gap-2 text-sm">
            <button class="min-w-[100px] py-1 bg-orange-400" @click="viewUser">View</button>
            <button class="min-w-[100px] py-1 bg-green-400" @click="uploadUser">Upload Excel</button>
            <button class="min-w-[100px] py-1 bg-blue-400" @click="insertUser">Insert</button>
          </div>
        </div>
      </div>
    </div>
    <modalViewAttribute v-if="openViewAttribute" @close="closeViewAttribute"/>
    <modalAttribute v-if="openInsertAttribute" @close="closeModalAttribute" />
    <modalUploadAttribute v-if="openUploadAttribute" @close="closeModalUploadAttribute" />

    <modalViewGlobal v-if="openViewGlobal" @close="closeViewGlobal"/>
    <modalGlobal v-if="openInsertGlobal" @close="closeModalGlobal" />
    <modalUploadGlobal v-if="openUploadGlobal" @close="closeModalUploadGlobal" />

    <modalViewInc v-if="openViewInc" @close="closeViewInc"/>
    <modalInc v-if="openInsertInc" @close="closeModalInc" />
    <modalUploadInc v-if="openUploadInc" @close="closeModalUploadInc" />

    <modalViewUser v-if="openViewUser" @close="closeViewUser"/>
    <modalUser v-if="openInsertUser" @close="closeModalUser" />
    <modalUploadUser v-if="openUploadUser" @close="closeModalUploadUser" />

    <modalViewGroup v-if="openViewGroup" @close="closeViewGroup"/>
    <modalGroup v-if="openInsertGroup" @close="closeModalGroup" />
    <modalUploadGroup v-if="openUploadGroup" @close="closeModalUploadGroup" />
  </div>
</template>

<script>
  import * as XLSX from 'xlsx';
  import axios from 'axios'

  import modalViewAttribute from '@/utils/masterdata/attribute/ModalViewAttribute.vue';
  import modalAttribute from '@/utils/masterdata/attribute/ModalNewAttribute.vue';
  import modalUploadAttribute from '@/utils/masterdata/attribute/ModalUploadExcel.vue';

  import modalViewGlobal from '@/utils/masterdata/globalAttribute/ModalViewGlobal.vue';
  import modalGlobal from '@/utils/masterdata/globalAttribute/ModalNewGlobal.vue';
  import modalUploadGlobal from '@/utils/masterdata/globalAttribute/ModalUploadExcel.vue';

  import modalViewInc from '@/utils/masterdata/inc/ModalViewInc.vue';
  import modalInc from '@/utils/masterdata/inc/ModalNewInc.vue';
  import modalUploadInc from '@/utils/masterdata/inc/ModalUploadExcel.vue';

  import modalViewUser from '@/utils/masterdata/user/ModalViewUser.vue';
  import modalUser from '@/utils/masterdata/user/ModalNewUser.vue';
  import modalUploadUser from '@/utils/masterdata/user/ModalUploadExcel.vue';

  import modalViewGroup from '@/utils/masterdata/group/ModalViewGroup.vue';
  import modalGroup from '@/utils/masterdata/group/ModalNewGroup.vue';
  import modalUploadGroup from '@/utils/masterdata/group/ModalUploadExcel.vue';

  export default {
    components: {
      modalViewAttribute,
      modalAttribute,
      modalUploadAttribute,
      modalViewGlobal,
      modalGlobal,
      modalUploadGlobal,
      modalViewInc,
      modalInc,
      modalUploadInc,
      modalViewUser,
      modalUser,
      modalUploadUser,
      modalViewGroup,
      modalGroup,
      modalUploadGroup
    },

    data() {
      return {
        excelData: [],
        file: null,
        saveClicked: false,

        openViewAttribute:false,
        openInsertAttribute:false,
        openUploadAttribute:false,

        openViewGlobal:false,
        openInsertGlobal:false,
        openUploadGlobal:false,

        openViewInc:false,
        openInsertInc:false,
        openUploadInc:false,

        openViewUser:false,
        openInsertUser:false,
        openUploadUser:false,

        openViewGroup:false,
        openInsertGroup:false,
        openUploadGroup:false,

        currentDate: ''
        // fileUrl: '../../public/'
      };
    },
    methods: {
      viewAttribute() {
        this.openViewAttribute = true
      },
      closeViewAttribute() {
        this.openViewAttribute = false
      },
      insertAttribute() {
        this.openInsertAttribute = true
      },
      closeModalAttribute() {
        this.openInsertAttribute = false
      },
      uploadAttribute() {
        this.openUploadAttribute = true
      },
      closeModalUploadAttribute() {
        this.openUploadAttribute = false
      },
      viewGlobal() {
        this.openViewGlobal = true
      },
      closeViewGlobal() {
        this.openViewGlobal = false
      },
      insertGlobal() {
        this.openInsertGlobal = true
      },
      closeModalGlobal() {
        this.openInsertGlobal = false
      },
      uploadGlobal() {
        this.openUploadGlobal = true
      },
      closeModalUploadGlobal() {
        this.openUploadGlobal = false
      },
      viewInc() {
        this.openViewInc = true
      },
      closeViewInc() {
        this.openViewInc = false
      },
      insertInc() {
        this.openInsertInc = true
      },
      closeModalInc() {
        this.openInsertInc = false
      },
      uploadInc() {
        this.openUploadInc = true
      },
      closeModalUploadInc() {
        this.openUploadInc = false
      },
      viewUser() {
        this.openViewUser = true
      },
      closeViewUser() {
        this.openViewUser = false
      },
      insertUser() {
        this.openInsertUser = true
      },
      closeModalUser() {
        this.openInsertUser = false
      },
      uploadUser() {
        this.openUploadUser = true
      },
      closeModalUploadUser() {
        this.openUploadUser = false
      },
      viewGroup() {
        this.openViewGroup = true
      },
      closeViewGroup() {
        this.openViewGroup = false
      },
      insertGroup() {
        this.openInsertGroup = true
      },
      closeModalGroup() {
        this.openInsertGroup = false
      },
      uploadGroup() {
        this.openUploadGroup = true
      },
      closeModalUploadGroup() {
        this.openUploadGroup = false
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          this.file = file;
          this.readFile(file);
        }
      },
      readFile(file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          this.excelData = excelData;
          this.file = file;
          console.log('excel data: ', this.excelData);
        };

        reader.readAsArrayBuffer(file);
      },
      getCurrentDate() {
        const date = new Date();
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        this.currentDate = formattedDate;
      },
      async saveMaterialToDatabase() {
        try {
          this.saveClicked = true
          const formData = new FormData();
          formData.append('excel_file', this.file);

          const response = await axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/material/bulkInsert`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          console.log(response);
          this.$toast.success('Data Material Berhasil Tersimpan', {
                duration: 2500,
                position: 'top-right'
              });
        } catch (error) {
          const errMsg = error.response.data.message
          this.$toast.error(`Gagal Menyimpan Data Material, ${errMsg}`, {
              duration: 2500,
              position: 'top-right'
          });
        }
        this.saveClicked = false
      },

      exportToExcel() {
        console.log(this.allData);
  const workbook = XLSX.utils.book_new();
  const wsData = [];

  const headerRow = [
    "ID", "Submitted Date", "QC Date", "Final Date", // New columns
    "Material Number", "Part Number", "Raw Data", "Raw Data 2", "Raw Data 3", "Raw Data 4",
    "Flag 1", "Flag 2", "INC", "MFR", "Group Code", "Group Name", "Cat", "QC", "Status",
    "Link", "INC Code", "Attribute Name", "Attribute Value", "After", "After with Attribute"
  ];

  wsData.push(headerRow);

  // Create an object to store unique material numbers and their corresponding "After with Attribute" values
  const materialAfterMap = {};

  this.allData.forEach(item => {
    const after = item.result || ''; // Handling if 'result' can be undefined

    const attributes = item.attributes || [];
    let afterWithAttribute = '';

    attributes.forEach(attribute => {
      let attributeValue = '';

      if (item.attributeValue) {
        const attributeValueJSON = JSON.parse(item.attributeValue);
        attributeValue = attributeValueJSON[attribute.attributeName] || '';
      }

      const rowData = [
        item.id, item.submittedAt, item.qcUpdate, item.finalUpdate,
        item.materialNumber, item.partNumber, item.rawData, item.rawData2, item.rawData3, item.rawData4,
        item.flag1, item.flag2, item.inc, item.mfr, item.groupCode, item.groupName, item.cat, item.qc,
        item.status, item.link, item.incName, attribute.attributeName, attributeValue, after, ''
      ];

      wsData.push(rowData);

      // Concatenate to "After with Attribute"
      if (attributeValue) {
        afterWithAttribute += `${attribute.attributeName}:${attributeValue}, `;
      }
    });

    // Remove the last comma and space
    afterWithAttribute = afterWithAttribute.slice(0, -2);

    // Update materialAfterMap
    if (afterWithAttribute) {
      if (!materialAfterMap[item.materialNumber]) {
        materialAfterMap[item.materialNumber] = afterWithAttribute;
      }
    }
  });

  // Add the "After with Attribute" value for each material number
  for (const materialNumber in materialAfterMap) {
    const afterWithAttribute = materialAfterMap[materialNumber];

    wsData.forEach((rowData, index) => {
      if (index !== 0 && rowData[4] === materialNumber) { // Check against Material Number index (4)
        wsData[index][wsData[0].length - 1] = afterWithAttribute;
      }
    });
  }

  const ws = XLSX.utils.aoa_to_sheet(wsData);
  XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');

  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  this.getCurrentDate();
  const fileName = `Sinergi Tuntas Solusi Cataloguing Tool_${this.currentDate}.xlsx`;

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}



    },
    computed: {
      allData() {
        return this.$store.getters.getAllData
      },
    },
    mounted() {
      this.$store.dispatch('fetchAllData')
    }
  };
</script>
