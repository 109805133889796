<template>
    <div class="w-screen min-h-screen flex flex-row justify-center">
      <div class="w-1/2 p-4 bg-red-500 gap-5 text-white flex flex-col justify-center font-normal text-center">
        <h1 class="text-4xl font-semibold">Sparepart Data Standarization & Cleaning</h1>
        <h2 class="text-lg  mb-10">
          Membuat Inventory & Warehouse Menjadi <strong>Transparan</strong> dan <strong>Akurat</strong>
        </h2>
        <div>
          <a href="https://sinergituntassolusi.com/contact" target="_blank" class="px-5 py-2 font-semibold border border-white hover:border-red-700 hover:bg-white hover:text-red-600 transition duration-300">
            Contact Us
          </a>
        </div>
      </div>
  
      <div class="w-1/2 flex justify-center items-center">
        <div class="flex flex-col justify-center items-center w-full h-full">
          <img src="../../public/sts-black.png" class="w-1/3" alt="STS">
          <!-- <h3 class="mb-6 text-4xl font-bold">Selamat Datang!</h3> -->
          <form @submit.prevent="login" class="w-3/5 flex justify-center flex-col">
            <div class="relative z-0 mb-7">
                <input type="text" id="username" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 focus:outline-none focus:ring-0 focus:border-red-600 peer" placeholder=" "
                v-model="username"/>
                <label for="floating_username" class="absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Username</label>
            </div>
            <div class="relative z-0 mb-7">
                <input type="password" id="floating_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 focus:outline-none focus:ring-0 focus:border-red-600 peer" placeholder=" " v-model="password"/>
                <label for="floating_password" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-red-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
            </div>
  
            <!-- <router-link to="/home" class="mx-auto w-full"> -->
              <button
                class="bg-red-600 w-full px-5 py-2 text-white font-semibold rounded-md mt-4"
                type="submit"
                >
                Login
              </button>
            <!-- </router-link> -->
          </form>
        </div>
      </div>
    </div>
  </template>
<script>
    export default {
      name:'LoginComponent',
      data() {
        return {
          username: '',
          password: '',
        };
      },
      methods: {
        login() {
          const userInfo = {
            name: this.username,
            password: this.password,
          };
          this.$store.dispatch('loginUser', userInfo)
            .then((success) => {
              if (success) {
                  this.$router.push('/home');
              } else {
                alert('Login gagal. Periksa username dan password Anda.');
              }
            });
        },
      },
      created() {
      },
    }
</script>