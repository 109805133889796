import 'tailwindcss/tailwind.css'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store';
import router from '../router'
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
// import dotenv from 'dotenv';

// dotenv.config();

createApp(App)
  .use(store)
  .use(router)
  .use(ToastPlugin)
  .mount('#app');