<template>
  <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div @click.stop class="h-4/5 w-5/6 bg-white flex flex-col p-3">
    
      <div class="flex flex-col h-full w-full gap-2">
        <div class="">
          <p class="text-md font-bold text-gray-700 text-center">INC</p>
        </div>
        <div class="w-full flex flex-col justify-betweem">
          <div class="flex justify-center gap-5 mb-4">
            <input type="text" v-model="searchIncCode" placeholder="Search INC Code" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
            <input type="text" v-model="searchIncName" placeholder="Search INC Name" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
          </div>

          <div class="flex w-full xl:max-h-[350px] 2xl:max-h-[450px] max-h-[280px] overflow-auto">
            <table class="border-2 w-full border-slate-950">
              <thead>
                <tr class="text-left">
                  <th>INC Code</th>
                  <th>INC Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredIncName" :key="item.id">
                  <td class="border-y border-gray-400">{{ item.INC }}</td>
                  <td class="border-y border-gray-400">{{ item.INCName }}</td>
                  <td class="border-y border-gray-400 flex flex-row flex-wrap gap-2">
                    <!-- <div class=""> -->
                        <button @click="editInc(item)" class="px-2 py-1 bg-yellow-500 text-white rounded-sm">Edit</button>
                        <button @click="deleteInc(item)" class="px-2 py-1 bg-red-500 text-white rounded-sm">Delete</button>
                    <!-- </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modalEditInc v-if="openEditInc" :inc="dataEditInc" @close="closeModalEdit"/>
  </div>
</template>

<script>
import ModalEditInc from '@/utils/masterdata/inc/ModalEditInc.vue';
export default {
    components: {
        ModalEditInc
    },
  data() {
    return {
      searchIncCode: '',
      searchIncName: '',
      dataEditInc: null,
      openEditInc: false
    };
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains('bg-gray-900')) {
        this.$emit('close'); //close
      }
    },
    closeModalEdit() {
        this.openEditInc = false
    },
    editInc(item) {
        console.log(item);
        this.dataEditInc = item
        this.openEditInc = true
    },
    deleteInc(item) {
        try {
            console.log('item id',item.id);
            this.$store.dispatch('deleteInc', item.id)
            this.$toast.success('Berhasil Menghapus Inc', {
                    duration: 2500,
                    position: 'top-right'
                });
        } catch (error) {
            this.$toast.error(`Gagal Menghapus data Inc, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
        }
    }
  },
  computed: {
    incData() {
      return this.$store.getters.getIncData;
    },

    filteredInc() {
      if (!this.searchIncCode) {
                return this.incData;
            }
            let searchTerms = this.searchIncCode.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredInc = this.incData;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredInc = filteredInc.filter(data => {
                        return keywords.every(keyword => {
                            return data.INC.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredInc = filteredInc.filter(data => {
                        return keywords.some(keyword => {
                            return data.INC.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredInc = filteredInc.filter(data => {
                        return data.INC.toLowerCase().includes(term);
                    });
                }
            });

            return filteredInc;
    },
    filteredIncName() {
      if (!this.searchIncName) {
                return this.filteredInc;
            }
            let searchTerms = this.searchIncName.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredIncName = this.filteredInc;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredIncName = filteredIncName.filter(data => {
                        return keywords.every(keyword => {
                            return data.INCName.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredIncName = filteredIncName.filter(data => {
                        return keywords.some(keyword => {
                            return data.INCName.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredIncName = filteredIncName.filter(data => {
                        return data.INCName.toLowerCase().includes(term);
                    });
                }
            });

            return filteredIncName;
    }
  },
  mounted() {
    this.$store.dispatch('fetchIncData')
    console.log('inc data',this.incData);
  }
};
</script>