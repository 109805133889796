<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-fit w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col py-2 h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Edit User</p>
                </div>
                <hr/>

                <form class="max-w-sm mx-auto w-full" @submit.prevent="updateUser">
                    <div>
                        <div class="mb-5 w-full">
                            <label for="userName" class="block mb-2 text-sm font-medium text-gray-900">User Name</label>
                            <input type="text" id="userName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Group Code" v-model="localUserData.userName" required>
                        </div>
                        <div class="mb-5 w-full">
                            <label for="userRole" class="block mb-2 text-sm font-medium text-gray-900">User Role</label>
                            <select name="role" id="userRole">
                                <option selected disabled value="">Pilih Role</option>
                                <option v-for="role in userRole" :key="role.id" :value="role.userRoleName">{{ role.userRoleName }}</option>
                            </select>
                            <!-- <input type="text" id="userRole" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Group Name" v-model="localUserData.groupName" required> -->
                        </div>
                    </div>
            
                    <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    props: {
        user: Object
    },
    data() {
        return {
            localUserData: {},
            // groupCode: '',
            // groupName: '',
        };
    },
    computed: {
        userRole() {
            return this.$store.getters.getUserRole;
        },
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                this.$emit('close');
            }
        },
        updateUser() {
            try{
                const user = {
                    id: this.localUserData.id,
                    userName: this.localUserData.userName,
                    userRole: this.localUserData.userRole,
                }
                // const listId = this.selectedRow.map(objek => objek.id);
                this.$store.dispatch('updateUser', { user });
                this.$toast.success('User Berhasil diUpdate', {
                    duration: 2500,
                    position: 'top-right'
                });
            }
            catch(error){
                this.$toast.error(`Gagal Update User, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        }
    },
    mounted() {
        this.$store.dispatch('fetchUserRole')
        this.localUserData = { ...this.user };
    }
}
</script>