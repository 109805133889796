<template>
    <div v-if="$store.state.userInfo" class="flex flex-row">
        <div class="w-[60px]">
            <Sidebar/>
        </div>
        <div class="w-full max-w-[calc(100vw-60px)]">
            <Content/>
        </div>
    </div>
</template>
<script>

    import Sidebar from '../components/sidebar.vue';
    import Content from "../components/main.vue";

export default {
    name:'HomeComponent',
    components: {
        Sidebar,
        Content
    },
}
</script>