<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div v-if="localData && localData.inc" @click.stop class="h-2/4 w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="text-center">
                <span class="font-bold">Edit Data</span> <br> 
                Material Number: {{data.materialNumber}}<br>
                Inc: {{data.inc}} - {{data.incName}}
            </div>
            <hr class="border border-b border-black w-full"/>
            <div class="overflow-y-auto">
                <!-- <div v-for="item in selectedAttributes" :key="item.attributeCode" class="flex justify-end gap-3 max-h-6 overflow-y-auto">
                    <label for="">{{item.attributeName}}:</label>
                    <input type="text" class="outline-none border border-red-500" v-model="item.attributeValue" @input="updateLiveDescription">
                </div> -->
                <div v-for="item in selectedAttributes" :key="item.attributeCode" class="flex justify-end gap-3 max-h-6 overflow-y-auto">
                    <!-- <label for="">{{getAttributeName(item.attributeCode)}}:</label> -->
                    <label for="">{{item.attributeName}}:</label>
                    <input type="text" class="outline-none border border-red-500" v-model="item.attributeValue" @input="updateLiveDescription">
                </div>
            </div>
            <div class="flex flex-col w-full">
                <label for="">Raw Data: </label>
                <input type="text" class="outline-none border border-red-500" :value="localData.rawData" disabled>
                <label for="">Result: </label>
                <label type="text" class="outline-none border border-red-500 min-h-[24px]">{{liveDescription}}</label>
            </div>
            <div class="w-full flex justify-center">
                <button @click="saveAndClose" class="border w-1/4 bg-green-600 text-white font-semibold">Save</button>
            </div> 
        </div>        
        <div v-else @click.stop class="bg-white flex flex-col items-center justify-between p-6 ">
            <div class="text-center">
                <span class="font-bold">Silahkan Pilih INC Terlebih Dahulu</span>
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localData: {}
        };
    },
    computed: {
        liveDescription() {
            return this.localData.result ? this.localData.result : '';
        },
        attributeData(){
            return this.$store.getters.getAttributes
        },
        selectedAttributes() {
            const currentInc = this.localData.inc;
            const attributesWithCurrentInc = this.attributeData.filter(item => item.inc === currentInc);

            attributesWithCurrentInc.sort((a, b) => a.sequence - b.sequence);

            if (!currentInc || typeof this.localData.attributeValue !== 'string') {
                return attributesWithCurrentInc.map(attribute => {
                return {
                    ...attribute,
                    attributeValue: '',
                };
                });
            }

            const selectedRowAttributes = JSON.parse(this.localData.attributeValue);

            return attributesWithCurrentInc.map(attribute => {
                const matchedAttribute = Object.entries(selectedRowAttributes).find(([key]) => {
                return key.toLowerCase() === attribute.attributeCode.toLowerCase() || key.toLowerCase() === attribute.attributeName.toLowerCase();
                });

                const attributeValue = matchedAttribute ? matchedAttribute[1] : '';

                return {
                ...attribute,
                attributeValue: attributeValue !== undefined ? attributeValue : '',
                };
            });
        }
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                // await this.updateItem();
                this.$emit('closeModal');
            }
        },
        saveAndClose(){
            try {
                    this.$toast.success('Update Attribute Berhasil Tersimpan', {
                        duration: 2500,
                        position: 'top-right'
                    });
                    this.updateItem();
                    this.$emit('saveAndCloseModal', this.localData);
                } catch (error) {
                    console.log(error);
                }
        },
        getAttributeName(attributeCode) {
            const matchedAttribute = this.attributeData.find(attribute => attribute.attributeCode.toLowerCase() === attributeCode.toLowerCase());
            return matchedAttribute ? matchedAttribute.attributeName : attributeCode;
        },

        updateLiveDescription() {
            let newDescription = '';
            const updatedAttributes = [...this.selectedAttributes];

            updatedAttributes.sort((a, b) => a.sequence - b.sequence);

            updatedAttributes?.forEach(attribute => {
                const attributeName = this.getAttributeName(attribute.attributeCode);
                const attributeValue = attribute.attributeValue;

                if (attributeName && attributeValue) {
                    newDescription += `${attributeValue};`;
                }
            });

            newDescription = newDescription.slice(0, -1);
            this.localData.result = newDescription;
        },

        convertHistoryToFormat(attributes) {
            let historyArray = [];
                if (this.localData.history) {
                historyArray = JSON.parse(this.localData.history);
                }
                historyArray.push(attributes);
            console.log(historyArray);
            return historyArray;
        },

        convertAttributesToFormat(attributes) {
            let result = {};
            attributes?.forEach(attribute => {
                if (attribute.attributeCode && attribute.attributeValue) {
                    result[attribute.attributeCode] = attribute.attributeValue;
                }
            });
            console.log('LOG RESULT', result);
            return result;
        },

        async updateItem() {
            try {
                // Mengonversi atribut terpilih menjadi format yang sesuai
                const formattedAttributes = this.convertAttributesToFormat(this.selectedAttributes);
                console.log('formatted Attributes--: ', formattedAttributes);

                // Mengonversi nilai atribut terformat menjadi string JSON
                const formattedValue = JSON.stringify(formattedAttributes);
                console.log('formatted Value--: ', formattedValue);

                // Mengonversi riwayat menjadi format yang sesuai dan mengonversi menjadi string JSON
                const formattedHistory = this.convertHistoryToFormat(formattedValue); 
                const formattedHistoryDesc = JSON.stringify(formattedHistory);

                // Jika status adalah 'NEW' dan inc tidak kosong, maka atur status menjadi 'NAMED'
                if (this.localData.status == 'NEW') {
                    if (this.localData.inc) {
                        this.localData.status = 'NAMED'
                    }
                }

                // Update properti localData dengan nilai baru
                this.localData.attributeValue = formattedValue;
                this.localData.history = formattedHistoryDesc;

                console.log('new item data: ', this.localData);

                // Melakukan pembaruan data pada store atau tempat lain yang diperlukan
                await this.$store.dispatch('updateMaterialData', this.localData);

                // Tambahkan pesan sukses atau tindakan lain yang diperlukan
                // this.$toast.success('Update Data Berhasil Tersimpan', {
                //     duration: 2500,
                //     position: 'top-right'
                // });

            } catch (error) {
                console.error('Error:', error);

                // Tambahkan pesan error atau tindakan lain yang diperlukan
                // this.$toast.error(`Update Data Gagal, ${error}`, {
                //     duration: 2500,
                //     position: 'top-right'
                // });
            }
}


        // async updateItem() {
        //     try {
        //         const formattedAttributes = this.convertAttributesToFormat(this.selectedAttributes);
        //         console.log('formatted Attributes--: ', formattedAttributes);

        //         const formattedValue = JSON.stringify(formattedAttributes);
        //         console.log('formatted Value--: ', formattedValue);

        //         const formattedHistory = this.convertHistoryToFormat(formattedValue); 
        //         const formattedHistoryDesc = JSON.stringify(formattedHistory);

        //         if (this.localData.status == 'NEW') {
        //             if (this.localData.inc) {
        //                 this.localData.status = 'NAMED'
        //             }
        //         }
                
        //         const newItemData = {
        //             id: this.localData.id,
        //             materialNumber: this.localData.materialNumber,
        //             partNumber: this.localData.partNumber,
        //             rawData: this.localData.rawData,
        //             rawData2: this.localData.rawData2,
        //             rawData3: this.localData.rawData3,
        //             rawData4: this.localData.rawData4,
        //             flag1: this.localData.flag1,
        //             flag2: this.localData.flag2,
        //             result: this.localData.result,
        //             attributeValue: formattedValue,
        //             history: formattedHistoryDesc,
        //             globalAttributeValue: this.localData.globalAttributeValue,
        //             mfr: this.localData.mfr,
        //             groupCode: this.localData.groupCode,
        //             cat: this.localData.cat,
        //             link: this.localData.link,
        //             inc: this.localData.inc,
        //             // descAfter: this.liveDescription,
        //             status: this.localData.status
        //         };

        //         console.log('new item data: ',newItemData);
        //         await this.$store.dispatch('updateMaterialData', newItemData);
        //     } catch (error) {
        //         console.error('Error:', error);
        //     }
        // }
    },
    mounted() {
        this.localData = { ...this.data };
        console.log('localData---', this.localData);
        if (this.localData.attributeValue) {
            try {
                const parsedAttributes = JSON.parse(this.localData.attributeValue);
                console.log('parsed attribute: -',parsedAttributes);
                // Lakukan pencocokan attributeName dengan nilai dari parsedAttributes
                this.localData.attributes.forEach(attribute => {
                    const matchedValue = parsedAttributes[attribute.attributeName];
                    if (matchedValue !== undefined) {
                        attribute.attributeValue = matchedValue;
                    }
                });
            } catch (error) {
                console.error('Error parsing attributeValue:', error);
            }
        }
    }

}
</script>