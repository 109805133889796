<template>
    <div
        class="flex flex-col sticky left-0 w-full h-screen bg-gradient-to-b from-red-500 to-red-600  gap-6 pt-8 justify-between items-center py-6">
        <div class="flex flex-col gap-5">
            <!-- <div class="text-black bg-red-200 p-2 rounded-full">APK</div> -->

            <div class="text-white hover:text-black cursor-pointer hover:bg-red-200 p-2 rounded-md duration-500"
            :class="{ 'bg-red-200': activeSidebarButton === 'itemListButton' }"
            @click="handleButtonClick('itemListButton', 'itemListContent')"
            title="Item By User"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                :style="{ stroke: activeSidebarButton === 'itemListButton' ? 'black' : 'currentColor' }"
                    class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
            </div>
            <div class="text-white hover:text-black cursor-pointer hover:bg-red-200 p-2 rounded-md duration-500"
            :class="{ 'bg-red-200': activeSidebarButton === 'allItemButton' }"
            @click="handleButtonClick('allItemButton', 'allItemContent')"
            title="All Item"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                :style="{ stroke: activeSidebarButton === 'allItemButton' ? 'black' : 'currentColor' }" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                </svg>
            </div>
            <div class="text-white hover:text-black cursor-pointer hover:bg-red-200 p-2 rounded-md duration-500"
            :class="{ 'bg-red-200': activeSidebarButton === 'dashboardButton' }"
            @click="handleButtonClick('dashboardButton', 'dashboardContent')"
            title="Dashboard"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                :style="{ stroke: activeSidebarButton === 'dashboardButton' ? 'black' : 'currentColor' }" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                </svg>
            </div>
            <!-- Add Data (Only Admin) -->
            <div v-if="$store.state.userInfo && $store.state.userInfo.role_id == 1" class="text-white hover:text-black cursor-pointer hover:bg-red-200 p-2 rounded-md duration-500"
            :class="{ 'bg-red-200': activeSidebarButton === 'masterDataButton' }"
            @click="handleButtonClick('masterDataButton', 'masterDataContent')"
            title="Admin"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :style="{ stroke: activeSidebarButton === 'masterDataButton' ? 'black' : 'currentColor' }" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
            </div>
        </div>
            <div class="text-white hover:text-black cursor-pointer hover:bg-red-200 p-2 rounded-md duration-500"
            @click="handleLogout"
            title="Logout"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                </svg>
            </div>
    </div>
    <div>

    </div>
</template>
<script>
export default {
    name: 'SidebarComponent',
    computed: {
        activeSidebarButton() {
            return this.$store.state.activeSidebarButton;
        },
    },
    methods: {
        handleButtonClick(buttonName, contentName) {
            this.$store.dispatch('setButtonAndContent', {
                buttonName: buttonName,
                contentName: contentName,
            });
        },
        handleLogout() {
            this.$store.dispatch('logoutUser');
            this.$router.push('/')
        },
    }
}
</script>