<template>
  <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div @click.stop class="h-4/5 w-5/6 bg-white flex flex-col p-3">
    
      <div class="flex flex-col h-full w-full gap-2">
        <div class="">
          <p class="text-md font-bold text-gray-700 text-center">User</p>
        </div>
        <div class="w-full flex flex-col justify-betweem">
          <div class="flex justify-center gap-5 mb-4">
            <input type="text" v-model="searchUserName" placeholder="Search User Name" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
            <input type="text" v-model="searchUserRole" placeholder="Search User Role" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
          </div>

          <div class="flex w-full xl:max-h-[350px] 2xl:max-h-[450px] max-h-[280px] overflow-auto">
            <table class="border-2 w-full border-slate-950">
              <thead>
                <tr class="text-left">
                  <th>User Name</th>
                  <th>User Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredUserRole" :key="item.id">
                  <td class="border-y border-gray-400">{{ item.userName }}</td>
                  <td class="border-y border-gray-400">{{ item.userRole }}</td>
                  <td class="border-y border-gray-400 flex flex-row flex-wrap gap-2">
                    <!-- <div class=""> -->
                        <button @click="editUser(item)" class="px-2 py-1 bg-yellow-500 text-white rounded-sm">Edit</button>
                        <button @click="deleteUser(item)" class="px-2 py-1 bg-red-500 text-white rounded-sm">Delete</button>
                    <!-- </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modalEditUser v-if="openEditUser" :user="dataEditUser" @close="closeModalEdit"/>
  </div>
</template>

<script>
import ModalEditUser from '@/utils/masterdata/user/ModalEditUser.vue';
export default {
    components: {
        ModalEditUser
    },
  data() {
    return {
      searchUserName: '',
      searchUserRole: '',
      dataEditUser: null,
      openEditUser: false
    };
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains('bg-gray-900')) {
        this.$emit('close'); //close
      }
    },
    closeModalEdit() {
        this.openEditUser = false
    },
    editUser(item) {
        console.log(item);
        this.dataEditUser = item
        this.openEditUser = true
    },
    deleteUser(item) {
        try {
            console.log('item id',item.id);
            this.$store.dispatch('deleteUser', item.id)
            this.$toast.success('Berhasil Menghapus User', {
                    duration: 2500,
                    position: 'top-right'
                });
        } catch (error) {
            this.$toast.error(`Gagal Menghapus data User, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
        }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.getAllUser;
    },

    filteredUserName() {
      if (!this.searchUserName) {
                return this.userData;
            }
            let searchTerms = this.searchUserName.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredUserCode = this.userData;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredUserCode = filteredUserCode.filter(data => {
                        return keywords.every(keyword => {
                            return data.userName.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredUserCode = filteredUserCode.filter(data => {
                        return keywords.some(keyword => {
                            return data.userName.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredUserCode = filteredUserCode.filter(data => {
                        return data.userName.toLowerCase().includes(term);
                    });
                }
            });

            return filteredUserCode;
    },
    filteredUserRole() {
      if (!this.searchUserRole) {
                return this.filteredUserName;
            }
            let searchTerms = this.searchUserRole.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredUserRole = this.filteredUserName;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredUserRole = filteredUserRole.filter(data => {
                        return keywords.every(keyword => {
                            return data.userRole.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredUserRole = filteredUserRole.filter(data => {
                        return keywords.some(keyword => {
                            return data.userRole.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredUserRole = filteredUserRole.filter(data => {
                        return data.userRole.toLowerCase().includes(term);
                    });
                }
            });

            return filteredUserRole;
    }
  },
  mounted() {
    this.$store.dispatch('fetchUserData')
    console.log('user data',this.userData);
  }
};
</script>