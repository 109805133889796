<template>
    <keep-alive>
        <ItemList v-if="activeContent==='itemListContent'" :userLogin="userLogin"/>
        <Items v-else-if="activeContent==='allItemContent'"/>
        <Dashboard v-else-if="activeContent==='dashboardContent'"/>
        <MasterData v-else-if="activeContent==='masterDataContent'"/>
    </keep-alive>
</template>
<script>

import Dashboard from './dashboard.vue';
import ItemList from './itemList.vue';
import Items from './Items.vue'
import MasterData from './masterData.vue';

export default {
    name: 'mainComponent',
    components: {
    ItemList,
    Items,
    Dashboard,
    MasterData
},
    computed: {
        activeContent() {
            return this.$store.state.activeContent;
        },
        userLogin() {
            return this.$store.state.userInfo;
        }
    },
}
</script>