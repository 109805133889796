<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-2/4 w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col justify-center h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Edit Flag Item</p>
                </div>
                <div class="w-full flex flex-col">
                    <label class="font-semibold" for="">Flag 1: </label>
                    <textarea rows="4" type="text" class="outline-none border border-red-500 text-sm" v-model="localFlagData.flag1"/>
                </div>
                <div class="w-full flex flex-col">
                    <label class="font-semibold" for="">Flag 2: </label>
                    <textarea rows="4" type="text" class="outline-none border border-red-500 text-sm" v-model="localFlagData.flag2"/>
                </div>
                <div class="w-full flex justify-center">
                    <button @click="saveAndClose" class="border w-1/4 bg-green-600 text-white font-semibold">Save</button>
                </div>  
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    props: {
        flagData: Object
    },
    data() {
        return {
            localFlagData: {}
        };
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                this.$emit('closeModalFlag');
            }
        },
        saveAndClose() {
            try {
                    this.$store.dispatch('updateMaterialData', this.localFlagData)
                    this.$toast.success('Update Status Berhasil Tersimpan', {
                        duration: 2500,
                        position: 'top-right'
                    });
                    this.$emit('saveAndCloseModalFlag', this.localFlagData);
                } catch (error) {
                    this.$toast.error(`Gagal Update data Status, ${error}`, {
                        duration: 2500,
                        position: 'top-right'
                    });
                }
        }
    },
    mounted() {
        this.localFlagData = { ...this.flagData };
        console.log('data Flag Modal-- ', this.localFlagData);
    }
}
</script>