import { createRouter, createWebHistory } from 'vue-router';
import Login from './src/views/Login.vue';
import Home from './src/views/Home.vue';
import Edit from './src/views/Edit.vue';
import store from './src/store';


const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit',
    name: 'edit',
    component: Edit,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isLoggedIn) {
    if (to.path !== '/') {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
