<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-fit w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col py-2 h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Add New Global Attribute</p>
                </div>
                <hr/>

                <form class="max-w-sm mx-auto" @submit.prevent="addGlobal">
                    <div>
                        <div class="mb-5">
                            <label for="global_code" class="block mb-2 text-sm font-medium text-gray-900">Global Attribute Code</label>
                            <input type="text" id="global_code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Global Attribute Code" v-model="globalCode" required>
                        </div>
                        <div class="mb-5">
                            <label for="global_name" class="block mb-2 text-sm font-medium text-gray-900">Global Attribute Name</label>
                            <input type="text" id="global_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Global Attribute Name" v-model="globalName" required>
                        </div>
                    </div>
            
                    <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    data() {
        return {
            globalCode: '',
            globalName: '',
        };
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                // this.$store.dispatch('updateMaterialData', this.localFlagData)
                this.$emit('close');
            }
        },
        addGlobal() {
            try{
                const globalData = {
                    globalCode: this.globalCode,
                    globalName: this.globalName
                }
                // const listId = this.selectedRow.map(objek => objek.id);
                this.$store.dispatch('createGlobal', { globalData });
                this.$toast.success('Global Attribute Baru Berhasil Tersimpan', {
                    duration: 2500,
                    position: 'top-right'
                });
            }
            catch(error){
                this.$toast.error(`Gagal Menyimpan Global Attribute Baru, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        }
    },
    computed: {
    }
}
</script>