<template>
    <div class="text-3xl font-bold h-full flex justify-center items-center">
        Dalam Tahap Pengembangan
    </div>
    <!-- <div class="flex flex-col">
        <div class=" h-screen w-screen max-w-[calc(100vw-60px)] bg-[#fffaee]">
            <div v-if="isLoggedIn" class="flex justify-end text-white px-4 text-md py-2 font-medium bg-slate-500">
                {{ $store.state.userInfo.username.toUpperCase() }} ({{ $store.state.userInfo.role.toUpperCase() }})
            </div>
            <div class="py-3 px-4 ">
                <div class="flex flex-row w-full justify-between gap-3">
                    <div class="w-2/3 flex flex-col gap-3">
                        <div class="flex flex-row justify-between">

                            <div class="p-3 bg-slate-200 rounded-lg shadow-lg w-[48%]">
                                <span class="text-sm text-gray-500">Total Status</span>
                                <table class="w-full">
                                    <thead>
                                        <tr class="bg-slate-800 text-white text-left">
                                            <th class="w-3/4 px-2">Total Status</th>
                                            <th class="w-1/4 px-2">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">NEW</td>
                                            <td class="px-2">500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="p-3 bg-slate-200 rounded-lg shadow-lg w-[48%]">
                                <span class="text-sm text-gray-500">Today Production</span>
                                 <table class="w-full">
                                    <thead>
                                        <tr class="bg-slate-800 text-white text-left">
                                            <th class="w-3/4 px-2">Total Status</th>
                                            <th class="w-1/4 px-2">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">NEW</td>
                                            <td class="px-2">500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        <div class="w-full">
                            <div class="flex flex-col justify-center gap-5">
                                
                                <div class="flex flex-row justify-between p-3 bg-slate-200 rounded-lg shadow-lg">
                                    <table class="w-1/3">
                                    <thead>
                                        <tr class="bg-slate-800 text-white">
                                            <th class="w-3/4 px-2">Total Status</th>
                                            <th class="w-1/4 px-2">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">NEW</td>
                                            <td class="px-2">500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14000</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>Chart</div>
                                </div>

                                <div class="flex flex-row justify-between p-3 bg-slate-200 rounded-lg shadow-lg">
                                    <table class="w-1/3">
                                    <thead>
                                        <tr class="bg-slate-800 text-white">
                                            <th class="w-3/4 px-2">Total Status</th>
                                            <th class="w-1/4 px-2">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">NEW</td>
                                            <td class="px-2">500</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2">ALL</td>
                                            <td class="px-2">14000</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>Chart</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 flex flex-col justify-between gap-7">
                        <div class="p-3 bg-slate-200 h-[40vh] rounded-lg shadow-lg w-full">
                            <span class="text-sm text-gray-500">
                                Total Production (Status: Catalogued)
                            </span>
                            <table class="w-full">
                                    <thead>
                                        <tr class="bg-slate-800 text-white text-left">
                                            <th class="w-3/4 px-2">Total Status</th>
                                            <th class="w-1/4 px-2">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="w-3/4 px-2">ALL</td>
                                            <td class="w-1/4 px-2">14500</td>
                                        </tr>
                                        <tr>
                                            <td class="w-3/4 px-2">NEW</td>
                                            <td class="w-1/4 px-2">500</td>
                                        </tr>
                                        <tr>
                                            <td class="w-3/4 px-2">ALL</td>
                                            <td class="w-1/4 px-2">14000</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        <div class="p-3 bg-slate-200 h-[40vh] rounded-lg shadow-lg">
                            <span class="text-sm text-gray-500">
                                Today Production (Status: Catalogued)
                            </span>
                            <table class="w-full">
                                    <thead>
                                        <tr class="bg-slate-800 text-white text-left">
                                            <th class="w-3/4 px-2">Total Status</th>
                                            <th class="w-1/4 px-2">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="w-3/4 px-2">ALL</td>
                                            <td class="w-1/4 px-2">14500</td>
                                        </tr>
                                        <tr>
                                            <td class="w-3/4 px-2">NEW</td>
                                            <td class="w-1/4 px-2">500</td>
                                        </tr>
                                        <tr>
                                            <td class="w-3/4 px-2">ALL</td>
                                            <td class="w-1/4 px-2">14500</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div> -->
</template>
<script>
export default {
    name: 'ItemList',
    data() {
        return {
            tableData: [
                {
                    id: 1,
                    no: '00000345',
                    inc: '00001',
                    incDesc: 'LAMPU, NEON',
                    descBefore: 'LAMPU BERWARNA 45-67RRTT DIPASANG DI GUDANG BELAKANG, BULAT DAN BERGULIR',
                    descAfter: 'LAMP,NEON,SST,45W,WHITE,THREADED',
                    grp: '6200',
                    groupDesc: 'LAMP AND FIXTURES',
                    partNumber: '45-67RRTT',
                    mfr: 'PHILLIPS',
                    whereUsed: '',
                    oldData: '',
                    cat: '',
                    status: 'NEW'
                },
                {
                    id: 2,
                    no: '00000346',
                    inc: '00002',
                    incDesc: 'BEARING, BALL',
                    descBefore: 'Bearing ball 6208',
                    descAfter: 'BEARING,BALL,DEEP,GROOVE,2 SEALED,6208',
                    grp: '6500',
                    groupDesc: 'BEARING,UNMOUNTED',
                    partNumber: '6208',
                    mfr: 'SKF',
                    whereUsed: '',
                    oldData: '',
                    cat: '',
                    status: 'NEW'
                },
                {
                    id: 3,
                    no: '00000347',
                    inc: '00003',
                    incDesc: 'CIRCUIT BREAKER',
                    descBefore: 'CIRCUIT, breaker DT-09',
                    descAfter: 'CIRCUIT BREAKER,2POLE,220VAC,DT-09',
                    grp: '7878',
                    groupDesc: 'ELECTRICAL',
                    partNumber: 'DT-09',
                    mfr: 'SCHNEIDER',
                    whereUsed: '',
                    oldData: '',
                    cat: '',
                    status: 'NEW'
                },
            ]
        }
    },
    computed: {
        userInfo() {
        return this.$store.state.userInfo;
        },
        isLoggedIn() {
        return this.$store.getters.isLoggedIn;
        },
    },
}
</script>