<template>
  <div class="w-screen h-screen bg-[#fffaee] flex justify-start flex-col">
    <router-link class="w-28" to="/home">
      <button class="bg-red-600 ml-8 w-auto px-5 py-2 text-white font-semibold rounded-sm mt-4">Home</button>
    </router-link>
    <div class="absolute right-8 top-2 bg-slate-500 rounded-sm">
      <table class="text-left text-white">
        <tr>
          <th class="border-b border-gray-300 px-3">User</th>
          <td class="border-b border-gray-300 px-3">{{$store.state.userInfo.username}}</td>
        </tr>
        <tr>
          <th class="border-b border-gray-300 px-3">Role</th>
          <td class="border-b border-gray-300 px-3">{{$store.state.userInfo.role_name}}</td>
        </tr>
        <tr>
          <th class="border-b border-gray-300 px-3">Cataloguer</th>
          <td class="border-b border-gray-300 px-3">{{currentItem.catName ? currentItem.catName : "Belum di Assign"}}</td>
        </tr>
        <tr>
          <th class="px-3">Status</th>
          <td class="px-3">{{currentItem.status}}</td>
        </tr>
      </table>
    </div>

    <div class="mx-auto">Item {{ currentItemIndex + 1 }} / {{ selectedRow.length }}</div>
    <div class="w-3/4 mx-auto">Material Number: {{ currentItem.materialNumber }}</div>

  <div class="max-h-[60%] overflow-auto items-center my-4">
    <table class="w-3/4 mx-auto text-center">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900" colspan="2">INC</td>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-gray-200">
          <td class="max-w-md w-1/2 border border-slate-900">
          <button @click="openInc" class="rounded-sm px-2 py-0 w-3/4 bg-blue-600 text-white" >Pilih Inc</button>
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">{{selectedIncDesc.INC}} - {{selectedIncDesc.INCName}}</td>
        </tr>
      </tbody>
    </table>

    <!-- Attribute -->
    <div class="">
      <table v-if="currentItem.inc" class="w-3/4 mx-auto text-center my-2">
        <thead>
          <tr class="bg-slate-700 text-white font-semibold">
            <td class="max-w-md w-1/2 border border-slate-900">Attribute Name</td>
            <td class="max-w-md w-1/2 border border-slate-900">Attribute Value</td>
          </tr>
        </thead>
        
          <tbody>
            <tr v-for="attribute in selectedAttributes" :key="attribute.id" class="bg-slate-200">
              <!-- <td>SHORT NAME</td> -->
              <td class="max-w-md w-1/2 border border-slate-900">
                {{attribute.attributeName}}
              </td>
              <td class="max-w-md w-1/2 border border-slate-900">
                <input type="text" v-model="attribute.attributeValue" class="w-full" @input="updateLiveDescription"/>
              </td>
            </tr>
          </tbody>
      </table>
    </div>

    <!-- Global Attribute -->
    <div class="w-full">
    <table class="w-3/4 mx-auto text-center mb-2">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900">Global Attribute Name</td>
          <td class="max-w-md w-1/2 border border-slate-900">Global Attribute Value</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in selectedGlobalAttributes" :key="item.globalattributeCode" class="bg-slate-200">
            <td class="max-w-md w-1/2 border border-slate-900">
              {{item.globalattributeName}}
            </td>
            <td class="max-w-md w-1/2 border border-slate-900">
              <input type="text" v-model="item.globalattributeValue" class="w-full" @input="updateGlobalAttr"/>
            </td>
          </tr>
      </tbody>
    </table>
    </div>

    <!-- Mfr & Part Number -->
    <table class="w-3/4 mx-auto text-center mb-2">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900">Manufacturer</td>
          <td class="max-w-md w-1/2 border border-slate-900">Part Number</td>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            <input type="text" class="w-full" v-model="currentItem.mfr">
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">
            <input type="text" class="w-full" v-model="currentItem.partNumber">
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Group -->
    <table class="w-3/4 mx-auto text-center mb-2">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900">Group Code</td>
          <td class="max-w-md w-1/2 border border-slate-900">Group Description</td>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
          <button @click="openGroup" class="rounded-sm px-2 py-0 w-3/4 bg-blue-600 text-white" >Pilih Group</button>
            <!-- <select name="" id="" class="bg-gray-100 max-w-xl"
            v-model="currentItem.groupCode">
              <option value="" selected disabled>Pilih Group</option>
              <option v-for="item in groupData" :key="item.id" :value="item.groupCode">{{ item.groupCode }} - {{item.groupName}}</option>
            </select> -->
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">{{selectedGroupDesc}}</td>
        </tr>
      </tbody>
    </table>

    <!-- Link -->
    <table class="w-3/4 mx-auto text-center mb-2">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900">Link</td>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            <input type="text" class="w-full text-center" v-model="currentItem.link">
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Flag -->
    <table class="w-3/4 mx-auto text-center mb-2">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900" colspan="2">Flag</td>
          <!-- <td class="max-w-md w-1/2 border border-slate-900">Part Number</td> -->
        </tr>
      </thead>
      <tbody>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            Flag 1
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">
            <input type="text" class="w-full" v-model="currentItem.flag1">
          </td>
        </tr>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            Flag 2
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">
            <input type="text" class="w-full" v-model="currentItem.flag2">
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Raw Data -->
    <table class="w-3/4 mx-auto text-center mb-2">
      <thead>
        <tr class="bg-slate-700 text-white font-semibold">
          <td class="max-w-md w-1/2 border border-slate-900" colspan="2">Raw Data</td>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            Raw Data 2
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">
            {{currentItem.rawData2}}
          </td>
        </tr>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            Raw Data 3
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">
            {{currentItem.rawData3}}
          </td>
        </tr>
        <tr class="bg-slate-200">
          <td class="max-w-md w-1/2 border border-slate-900">
            Raw Data 4
          </td>
          <td class="max-w-md w-1/2 border border-slate-900">
            {{currentItem.rawData4}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="absolute left-0 flex flex-row items-center top-1/2 -translate-y-1/2 overflow-hidden">
  <div class="flex flex-col">
    <div :class="isShowDesc ? 'visible' : 'hidden'" class="font-semibold text-white w-80 bg-slate-500 max-h-[250px] overflow-y-scroll py-1 border border-slate-100">
      <p class="text-xl mb-2">Complete Desc:</p>
      <p class="text-sm" v-for="(item, index) in selectedAttributes" :key="index">
        {{item.attributeName + ': ' + item.attributeValue}}
      </p>
    </div>
    <div :class="isShowDesc ? 'visible' : 'hidden'" class="font-semibold text-white w-80 bg-slate-500 max-h-[250px] overflow-y-scroll py-1 border border-slate-100">
      <p class="text-xl mb-2">History:</p>
      <!-- <p class="text-sm">{{JSON.parse(currentItem.history)}}</p> -->
        <!-- {{item.attributeName + ': ' + item.attributeValue}} -->
        <div v-if="currentItem.historyDesc">
          <p class="text-sm" v-for="(item, index) in JSON.parse(currentItem.historyDesc)" :key="index">
            {{JSON.parse(item)}}
            <br><br>
          </p>
          <!-- <p class="text-sm" v-for="(item, index) in JSON.parse(currentItem.history)" :key="index">
            {{JSON.parse(item)}}
            <br><br>
          </p> -->
        </div>
    </div>
  </div>
    <button @click="showDesc" class="py-2 px-1 bg-red-500 rounded-r-md text-white font-semibold">
      <svg :class="isShowDesc ? 'rotate-180' : 'rotate-0'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 duration-300">
        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
      </svg>
    </button>
  </div>

  <div class="absolute bottom-28 right-1 flex px-5 gap-8">
    <div class="flex justify-center flex-col gap-3">
      <button 
        @click="prevItem"
        class="py-1 px-2 text-white rounded-sm w-32"
        :disabled="currentItemIndex === 0"
        :class="currentItemIndex === 0 ? 'bg-red-300' : 'bg-red-500'"
        ref="prevButton">
        Prev <strong>(Alt + Q)</strong>
      </button>
      <button 
        @click="nextItem"
        class="py-1 px-2 text-white rounded-sm w-32"
        :disabled="currentItemIndex === selectedRow.length - 1"
        :class="currentItemIndex === selectedRow.length - 1 ? 'bg-red-300' : 'bg-red-500'"
        ref="nextButton">
        Next <strong>(Alt + W)</strong>
      </button>
    </div>
  </div>

    <div class="absolute bottom-0 w-full bg-slate-300 h-24">

      <div class="flex flex-row justify-evenly items-center h-full">
        <div class="w-3/4">
                <div class="flex flex-col text-sm gap-3 w-full">
                    <div class="flex flex-row w-full items-center">
                        <label for="materialNum" class="w-32 text-sm font-medium text-gray-900">Nomor Material</label>
                        <input type="text" id="materialNum" class="w-full px-2 py-0 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 cursor-default font-courier"
                        :value="currentItem.materialNumber"
                        disabled>
                    </div>
                    <div class="flex flex-row w-full items-center">
                        <label for="rawData" class="w-32 font-medium text-gray-900">Raw Data</label>
                        <input type="text" id="rawData" class="w-full px-2 py-0 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 cursor-default font-courier"
                        :value="currentItem.rawData"
                        disabled>
                    </div>
                    <div class="flex flex-row w-full items-center">
                        <label for="after" class="w-32 font-medium text-gray-900">After</label>
                        <input type="text" id="after" class="w-full px-2 py-0 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 cursor-default font-courier" :value="liveDescription" disabled>
                    </div>
                    
                </div>
            </div>
              <div class="flex flex-col gap-3">
                <button v-if="$store.state.userInfo.role_id&&$store.state.userInfo.role_id == 4 || $store.state.userInfo.role_id == 2"
                 class="px-2 py-0 text-white bg-blue-500 rounded-sm text-center font-semibold w-32" @click.prevent="changeStatus('cat')">CAT</button>
                <button v-if="$store.state.userInfo.role_id&&$store.state.userInfo.role_id == 4" class="px-2 py-0 text-white bg-blue-500 rounded-sm text-center font-semibold w-32" @click.prevent="changeStatus('named')">NAMED</button>
                <button v-if="$store.state.userInfo.role_id&&$store.state.userInfo.role_id == 2 || $store.state.userInfo.role_id == 3" class="px-2 py-0 text-white bg-blue-500 rounded-sm text-center font-semibold w-32" @click.prevent="changeStatus('qc')">QC</button>
                <button v-if="$store.state.userInfo.role_id&&$store.state.userInfo.role_id == 3" class="px-2 py-0 text-white bg-blue-500 rounded-sm text-center font-semibold w-32" @click.prevent="changeStatus('final')">FINAL</button>
              </div>
              <div class="flex flex-col">
                <button class="px-2 py-0 text-white bg-green-600 rounded-sm text-center font-semibold w-32" @click="updateItem">SAVE</button>
              </div>
      </div>

    </div>
  </div>
  <modal-inc v-if="openModalInc" :data="selectedInc" @closeModalInc="closeModalInc" @saveAndCloseModalInc="saveAndCloseModalInc" />
  <modal-group v-if="openModalGroup" :data="selectedGroup" @closeModalGroup="closeModalGroup" @saveAndCloseModalGroup="saveAndCloseModalGroup" />
</template>

<script>
import modalInc from '@/utils/ModalInc.vue'
import modalGroup from '@/utils/ModalGroup.vue'

export default {
  name: 'EditComponent',
  components: {
    modalInc,
    modalGroup,
  },
  data() {
    return {
      // liveDescription:'',
      // selectedRow: [],
      selectedIncCode: '',
      selectedInc: {},
      selectedGroup: {},

      editingItem: null,
      editingItemIndex: -1,
      currentItemIndex: 0,
      isShowDesc: false,

      editedAttributes: {},

      openModalInc: false,
      openModalGroup: false
    };
  },

  computed: {
    selectedRow() {
      return this.$store.getters.getSelectedRow;
    },
    parseData(data) {
      if(data){
        return  JSON.parse(data);
      } else {
        return data
      }
    },
    incData() {
      return this.$store.getters.getIncData
    },
    attributeData(){
      return this.$store.getters.getAttributes
    },
    globalAttributeData(){
      return this.$store.getters.getGlobalAttributes
    },
    groupData(){
      return this.$store.getters.getGroupData
    },
    currentItem() {
      return this.selectedRow[this.currentItemIndex];
    },
    liveDescription() {
      return this.currentItem.result ? this.currentItem.result : '';
    },
    selectedIncDesc() {
      const selectedInc = this.incData?.find(item => item.INC === this.currentItem.inc);
      return selectedInc ? selectedInc : '';
    },
    selectedGroupDesc() {
      const selectedGroup = this.groupData?.find(item => item.groupCode === this.currentItem.groupCode);
      return selectedGroup ? selectedGroup.groupName : '';
    },
    
    selectedAttributes() {
      const currentInc = this.currentItem.inc;
      const attributesWithCurrentInc = this.attributeData.filter(item => item.inc === currentInc);

      if (!currentInc || typeof this.currentItem.attributeValue !== 'string') {
        return attributesWithCurrentInc.map(attribute => {
          return {
            ...attribute,
            attributeValue: '',
          };
        });
      }

      const selectedRowAttributes = JSON.parse(this.currentItem.attributeValue);

      attributesWithCurrentInc.sort((a, b) => a.sequence - b.sequence);

      return attributesWithCurrentInc.map(attribute => {
        const matchedAttribute = Object.entries(selectedRowAttributes).find(([key]) => {
          return key.toLowerCase() === attribute.attributeCode.toLowerCase() || key.toLowerCase() === attribute.attributeName.toLowerCase();
        });

        const attributeValue = matchedAttribute ? matchedAttribute[1] : '';

        return {
          ...attribute,
          attributeValue: attributeValue !== undefined ? attributeValue : '',
        };
      });
    },

    selectedGlobalAttributes() {
      if (typeof this.currentItem.globalAttributeValue !== 'string') {
        return this.globalAttributeData?.map(globalAttribute => {
          return {
            ...globalAttribute,
            globalAttributeValue: '',
          };
        });
      }
      const selectedRowAttributes = JSON.parse(this.currentItem.globalAttributeValue);

      return this.globalAttributeData.map(attribute => {
        const matchedAttribute = Object.entries(selectedRowAttributes).find(([key]) => {
          return key.toLowerCase() === attribute.globalattributeCode.toLowerCase() || key.toLowerCase() === attribute.globalattributeName.toLowerCase();
        });

        const globalattributeValue = matchedAttribute ? matchedAttribute[1] : '';

        return {
          ...attribute,
          globalattributeValue: globalattributeValue !== undefined ? globalattributeValue : '',
        };
      });
    }

  },

  created() {
    this.$store.dispatch('fetchIncData')
    this.$store.dispatch('fetchGlobalAttributeData')
    
    window.addEventListener('keydown', this.handleKeyPress);

    console.log('currentItem: ',this.currentItem);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  },

  methods: {
    showDesc() {
      this.isShowDesc = !this.isShowDesc;
    },
    handleKeyPress(event) {
      if (event.altKey && event.key === 'q' && this.currentItemIndex > 0) {
        this.prevItem();
      }
      if (event.altKey && event.key === 'w' && this.currentItemIndex < this.selectedRow.length - 1) {
        this.nextItem();
      }
    },
    openInc(){
      this.openModalInc = true;
    },
    openGroup(){
      this.openModalGroup = true;
    },
    closeModalInc() {
      this.openModalInc = false;
    },
    closeModalGroup() {
      this.openModalGroup = false;
    },

    saveAndCloseModalInc(data) { 
      this.currentItem.inc = data.INC
      this.openModalInc = false;
    },

    saveAndCloseModalGroup(data) { 
      console.log('group data',data);
      this.currentItem.groupCode = data.groupCode
      this.openModalGroup = false;
    },
    
    async prevItem() {
      if (this.currentItemIndex > 0) {
        await this.updateItem()
        this.currentItemIndex--;
      }
    },
    async nextItem() {
      if (this.currentItemIndex < this.selectedRow.length - 1) {
        await this.updateItem()
        this.currentItemIndex++;
      }
    },

    getEditedAttributes() {
      const currentItem = this.selectedRow[this.currentItemIndex];
      if (currentItem && currentItem.id in this.editedAttributes) {
        return this.editedAttributes[currentItem.id];
      } else {
        return {
          selectedAttributes: [],
          selectedGlobalAttributes: []
        };
      }
    },

    updateLiveDescription() {
      let newDescription = '';
      console.log('selectedAttributes ---: ', this.selectedAttributes);
      const updatedAttributes = [...this.selectedAttributes];

      updatedAttributes.sort((a, b) => a.sequence - b.sequence);

      updatedAttributes.forEach(attribute => {
        if (attribute.attributeValue) {
          newDescription += `${attribute.attributeValue};`;
        }
      });
      console.log('Update Live', updatedAttributes);
      console.log('New Desc', newDescription);

      newDescription = newDescription.slice(0, -1);
      this.currentItem.result = newDescription;
    },

    updateGlobalAttr() {
      console.log('selectedAttributes ---: ', this.selectedGlobalAttributes);
      const updatedGlobalAttributes = [...this.selectedGlobalAttributes];

      console.log('---',updatedGlobalAttributes);
    },

    editItem(index) {
      this.editingItemIndex = index;
      this.editingItem = { ...this.selectedRow[index] };
    },

    changeStatus(status) {
      try{
        this.currentItem.status = status.toUpperCase()
        this.updateItem()
      } catch {
        alert("Gagal mengubah status")
      }
    },

    convertAttributesToFormat(attributes) {
      let result = {};
      attributes.forEach(attribute => {
        if (attribute.attributeName && attribute.attributeValue) {
          result[attribute.attributeName] = attribute.attributeValue;
        }
      });
      return result;
    },

    convertHistoryToFormat(attributes) {
      let historyArray = [];
        if (this.currentItem.history) {
          historyArray = JSON.parse(this.currentItem.history);
        }
        historyArray.push(attributes);
      console.log(historyArray);
      return historyArray;
    },

    convertGlobalAttributesToFormat(attributes) {
      let result = {};
      attributes.forEach(attribute => {
        if (attribute.globalattributeName && attribute.globalattributeValue) {
          result[attribute.globalattributeName] = attribute.globalattributeValue;
        }
      });
      return result;
    },

    async updateItem() {
      try {
        // Attribute
        console.log('tipe data',typeof this.selectedAttributes);
        console.log('tipe data current',typeof this.currentItem.attributeValue);
        const formattedAttributes = this.convertAttributesToFormat(this.selectedAttributes);
        const formattedGlobalAttributes = this.convertGlobalAttributesToFormat(this.selectedGlobalAttributes);
        const formattedValue = JSON.stringify(formattedAttributes);
        const formattedGlobalValue = JSON.stringify(formattedGlobalAttributes);
        const formattedHistory = this.convertHistoryToFormat(formattedValue); 
        const formattedHistoryDesc = JSON.stringify(formattedHistory);


        if (this.currentItem.status == 'NEW') {
          if (this.currentItem.inc) {
            this.currentItem.status = 'NAMED'
          }
        }

        
        const newItemData = {
          id: this.currentItem.id,
          materialNumber: this.currentItem.materialNumber,
          partNumber: this.currentItem.partNumber,
          rawData: this.currentItem.rawData,
          rawData2: this.currentItem.rawData2,
          rawData3: this.currentItem.rawData3,
          rawData4: this.currentItem.rawData4,
          flag1: this.currentItem.flag1,
          flag2: this.currentItem.flag2,
          result: this.currentItem.result,
          attributeValue: formattedValue,
          history: formattedHistoryDesc,
          globalAttributeValue: formattedGlobalValue,
          mfr: this.currentItem.mfr,
          groupCode: this.currentItem.groupCode,
          cat: this.currentItem.cat,
          link: this.currentItem.link,
          inc: this.currentItem.inc,
          status: this.currentItem.status
        };

        console.log('new item data: ',newItemData);
        await this.$store.dispatch('updateMaterialData', newItemData);
        await this.$store.dispatch('updateSelectedItem', { index: this.currentItem.id, newItemData });
        this.currentItem.attributeValue = newItemData.attributeValue;
        console.log('selected Roww: ', this.selectedRow);

        this.currentItem.globalAttribute = newItemData.globalAttributeValue
        // await this.$store.dispatch('updateAttributeData', this.selectedAttributes);

        this.$toast.success('Update Data Berhasil Tersimpan', {
          duration: 500,
          position: 'top-left'
        });
      } catch (error) {
        this.$toast.error(`Update Data Gagal, ${error}`, {
          duration: 500,
          position: 'top-left'
        });

        console.error('Error:', error);
      }
    }
  },
};
</script>
