<template>
    <div class="flex flex-col h-screen">
        <div class=" h-full w-full bg-[rgb(255,250,238)]">
            <div v-if="isLoggedIn" class="flex justify-end text-white px-4 text-md py-2 font-medium bg-slate-500">
                {{ $store.state.userInfo.username&&$store.state.userInfo.username.toUpperCase() }} ({{ $store.state.userInfo.role_name&&$store.state.userInfo.role_name.toUpperCase() }})
            </div>
            <div v-if="showData.length > 0" class="flex flex-row justify-center items-center gap-5">
                <div class="flex flex-row gap-2 justify-center items-center">
                    <div class="flex flex-col">
                        <button class="bg-gradient-to-r from-red-500 to-red-700 w-32 my-2 ml-2 px-2 py-1 text-sm font-semibold rounded-sm text-white" @click="expandTable=!expandTable">{{expandTable? 'Perkecil Tabel' : 'Tampilan Penuh'}}</button>
                        <button class="bg-gradient-to-r from-blue-500 to-blue-700 w-32 my-2 ml-2 px-2 py-1 text-sm font-semibold rounded-sm text-white" @click="showGlobalAttribute=!showGlobalAttribute">{{showGlobalAttribute? 'Sembunyikan Global Attribute' : 'Tampilkan Global Attribute'}}</button>
                    </div>

                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" v-model="highlightEnabled" class="sr-only peer">
                        <div class="w-11 h-6 bg-red-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-red-600"></div>
                        <span class="ml-3 text-xs font-medium text-gray-900">Compare</span>
                    </label>
                </div>
                <!-- Search Form -->
                <form class="flex flex-col" @submit.prevent="searchItems">
                    <div class="flex flex-row gap-2">
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchNumber" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Material Number">
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="searchInc" v-model="searchInc" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="INC / INC Name">
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="searchBefore" v-model="searchBefore" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Description (BEFORE)">
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="searchAfter" v-model="searchAfter" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Description (AFTER)">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchPartNumber" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Part Number">
                        </div>
                    </div>
                    <div class="flex flex-row gap-2">
                        
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchMfr" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Manufacturer">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchFlag1" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Flag 1">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchStatus" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Status">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchAll" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Semua Kolom">
                        </div>
                        <div class="flex items-center">
                            <button type="submit" class="py-1 px-2 my-2 bg-green-600 rounded-sm text-white font-semibold">Cari</button>
                        </div>
                        <div class="flex items-center">
                            <button type="button" @click="resetFilters" class="py-1 px-2 my-2 bg-red-600 rounded-sm text-white font-semibold">Reset</button>
                        </div>
                    </div>
                </form>

                <div class="relative ml-auto px-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    </div>
                    <input type="search" id="highlight" v-model="highlightText" class="block w-full py-1 px-2 my-2 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Highlight Kata (After)">
                </div>
            </div>
            <!-- Tabel -->
            <div ref="scroll" @scroll="handleScroll" v-if="showData.length > 0" class="flex justify-start w-full max-h-[calc(100vh-230px)] overflow-auto">
                <table class="w-full text-left font-courier text-black font-medium text-[12px]">
                <thead class="text-gray-100 uppercase bg-gray-700 cursor-pointer h-8 sticky top-0">
                    <tr>
                        <th class="px-1 py-0 leading-none border-y border-gray-300">
                            <input type="checkbox" class="w-5 h-5" @change="selectAll()">
                        </th>
                        <th class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('materialNumber')">NO</th>
                        <th class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('inc')">INC</th>
                        <th class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('incName')">INC DESC</th>
                        <th class="px-1 py-0 leading-none border-y border-gray-300 min-w-[400px]" @click="toggleSort('rawData')">DESCRIPTION (BEFORE)</th>
                        <th class="px-1 py-0 leading-none border-y border-gray-300 min-w-[400px]" @click="toggleSort('result')">DESCRIPTION (AFTER)</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('groupCode')">GRP</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('groupName')">GROUP DESC</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('partNumber')">PART NUMBER</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('mfr')">MFR</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('flag1')">FLAG 1</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('flag2')">FLAG 2</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('rawData2')">RAW DATA 2</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('rawData3')">RAW DATA 3</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('rawData4')">RAW DATA 4</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('link')">LINK</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('cat')">CAT</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('qc')">QC</th>
                        <th v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @click="toggleSort('status')">STATUS</th>
                        <th v-show="showGlobalAttribute" v-for="(attribute, index) in globalAttributeData" :key="index" class="bg-blue-700 px-1 py-0 leading-none border-y border-gray-300">{{ attribute.globalattributeName }}</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr v-for="data in showData" :key="data.id" @click="handlePreview(data)" class="cursor-pointer hover:bg-blue-200">
                        <td class="px-1 py-0 leading-none m-0 border-y border-gray-300 ">
                            <input type="checkbox" :value="data.id" v-model="checkedRows">
                        </td>
                        <td class="px-1 py-0 leading-none border-y border-gray-300">{{ data.materialNumber }}</td>
                        <td class="px-1 py-0 leading-none border-y border-gray-300">{{ data.inc }}</td>
                        <td class="px-1 py-0 leading-none border-y border-gray-300">{{ data.incName}}</td>
                        <td class="px-1 py-0 leading-none border-y border-gray-300">
                            <span v-if="data.rawData !== null || data.result !== null" v-html="highlightMissingWords(data.rawData, data.result)"></span>
                        </td>
                        <td class="px-1 py-0 leading-none border-y border-gray-300" @dblclick="showModal(data)">
                            <div v-if="!highlightEnabled && data.result !== null" v-html="highlightDescriptionAfter(data)"></div>
                            <span v-if="highlightEnabled && data.rawData !== null && data.result !== null" v-html="highlightMissingWords(data.result, data.rawData)"></span>
                        </td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.groupCode }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.groupName }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @dblclick="updatePn(data)">{{ data.partNumber }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @dblclick="updateMfr(data)">{{ data.mfr }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @dblclick="updateFlag(data)">{{ data.flag1 }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300" @dblclick="updateFlag(data)">{{ data.flag2 }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.rawData2 }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.rawData3 }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.rawData4 }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.link }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.catName }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.qcName }}</td>
                        <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-300">{{ data.status }}</td>
                        <td v-show="showGlobalAttribute" v-for="(attribute, index) in globalAttributeData" :key="index" class="px-1 py-0 leading-none border-y border-gray-300">
                            {{ data.globalAttributeValue && JSON.parse(data.globalAttributeValue)[attribute.globalattributeName] ? JSON.parse(data.globalAttributeValue)[attribute.globalattributeName] : '' }}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div v-else class="w-full h-full flex items-center justify-center text-2xl font-bold">Belum ada item</div>
        </div>
        
        <div class="h-[90px] bg-slate-300 w-full flex flex-row gap-4 justify-between items-center px-5 text-xs">
            <div class="w-2/3">
                <div class="flex flex-col gap-2 w-full">
                    <div class="flex flex-row w-full items-center">
                        <label for="raw-input" class="w-32 font-medium text-gray-900">Before</label>
                        <input type="text" id="raw-input" class="w-full px-2 py-0 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 cursor-default font-courier" :value="selectedDesc" disabled>
                    </div>
                    <div class="flex flex-row w-full items-center">
                        <label for="after-input" class="w-32 font-medium text-gray-900">After</label>
                        <input type="text" id="after-input" class="w-full px-2 py-0 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 cursor-default font-courier" :value="selectedDescAfter" disabled>
                    </div>
                    <div class="flex flex-row w-full items-center">
                        <label for="nomor-input" class="w-32 font-medium text-gray-900">Nomor</label>
                        <input type="text" id="nomor-input" class="w-full px-2 py-0 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 cursor-default font-courier" :value="selectedNumber" disabled>
                    </div>
                </div>
            </div>
            <div class="w-1/3">
                <div class="flex flex-row items-center w-full gap-1">
                    <div class="flex flex-col w-full">
                        <div v-if="$store.state.userInfo && $store.state.userInfo.role_id == '1'" class="flex flex-row w-full items-center gap-3 px-2">
                            <label for="cat" class="w-1/2 mb-2 font-medium text-gray-900">Cataloguer</label>
                            <select name="cat" id="cat" class="rounded-sm px-2 py-0 w-3/4"
                            v-model="selectedCat"
                            :disabled="selectedRow.length === 0"
                            @change="updateCat">
                                <option selected disabled value="">Pilih Cataloguer</option>
                                <option v-for="cat in cataloguerData" :key="cat" :value="cat">{{ cat.userName }}</option>
                            </select>
                        </div>
                        <div v-if="$store.state.userInfo && $store.state.userInfo.role_id !== '2'" class="flex flex-row w-full items-center gap-3 px-2">
                            <label for="small-input" class="w-1/2 mb-2 font-medium text-gray-900">INC</label>
                            <button @click="openInc" class="rounded-sm px-2 py-0 w-3/4 bg-blue-600 text-white disabled:bg-slate-200 disabled:text-slate-400" :disabled="selectedRow.length == 0 || isDisabled">Pilih Inc</button>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <div v-if="$store.state.userInfo.role_id != '1'" class="flex flex-row w-full items-center gap-3 px-2">
                            <label for="small-input" class="w-1/2 mb-2 font-medium text-gray-900">Status</label>
                            <select name="status" id="status" class="rounded-sm px-2 py-0 w-3/4"
                            :disabled="selectedRow.length === 0"
                            @change="updateSelectedStatus($event.target.value)">
                                <option selected disabled value="">Pilih Status</option>
                                <!-- <option value="new">NEW</option> -->
                                <option value="named" v-if="$store.state.userInfo && $store.state.userInfo.role_id == '4'">NAMED</option>
                                <option value="cat" v-if="$store.state.userInfo && $store.state.userInfo.role_id == '4' || $store.state.userInfo.role_id == '2'">CATALOUGED</option>
                                <option value="qc" v-if="$store.state.userInfo && $store.state.userInfo.role_id == '2'">QC</option>
                                <option value="approved" v-if="$store.state.userInfo && $store.state.userInfo.role_id == '3'">FINAL</option>
                            </select>
                        </div>
                        <div v-if="$store.state.userInfo && $store.state.userInfo.role_id == '1'" class="flex flex-row w-full items-center gap-3 px-2">
                            <label for="qc" class="w-1/2 mb-2 font-medium text-gray-900">QC</label>
                            <select name="qc" id="qc" class="rounded-sm px-2 py-0 w-3/4"
                            :disabled="selectedRow.length === 0"
                            @change="updateQc($event.target.value)">
                                <option selected disabled value="">Pilih QC</option>
                                <option v-for="qc in qcData" :key="qc" :value="qc.userId">{{ qc.userName }}</option>
                            </select>
                        </div>
                    </div>
                    
                    
                    <div v-if="$store.state.userInfo && ['1','2','4'].includes($store.state.userInfo.role_id)" class="flex flex-row w-full font-semibold"> 
                        <div class="w-full px-4">
                            <button class="px-2 py-4 text-white bg-green-600 rounded-sm text-center font-semibold w-full disabled:bg-gray-500 disabled:text-gray-300" :disabled="selectedRow.length === 0" @click="editData">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-edit v-if="openModalEdit" :data="directEditData" @closeModal="closeModal" @saveAndCloseModal="saveAndCloseModal" />
        <modal-mfr v-if="openModalMfr" :data="dataEditMfr" @closeModalMfr="closeModalMfr" @saveAndCloseModalMfr="saveAndCloseModalMfr"/>
        <modal-flag v-if="openModalFlag" :flagData="dataEditFlag" @closeModalFlag="closeModalFlag" @saveAndCloseModalFlag="saveAndCloseModalFlag" />
        <modal-pn v-if="openModalPn" :data="dataEditPn" @closeModalPn="closeModalPn" @saveAndCloseModalPn="saveAndCloseModalPn"/>
        <modal-inc v-if="openModalInc" :data="selectedInc" @closeModalInc="closeModalInc" @saveAndCloseModalInc="saveAndCloseModalInc" @resetSelectedRow="resetSelectedRow" />
    </div>
</template>
<script>

    import modalMfr from '@/utils/ModalMfr.vue'
    import modalEdit from '@/utils/ModalEditAttribute.vue'
    import modalFlag from '@/utils/ModalFlag.vue'
    import modalPn from '@/utils/ModalPn.vue'
    import modalInc from '@/utils/ModalInc.vue'

export default {
    props: {
        userLogin: Object
    },
    name: 'ItemByUser',
    components: {
        modalEdit,
        modalMfr,
        modalFlag,
        modalPn,
        modalInc
    },
    data() {
        return {
            isSearching: false,
            materialData: null,
            checkedRows: [],

            sortOptions: {
                materialNumber: {
                    active: false,
                    direction: 'asc'
                },
                inc: {
                    active: false,
                    direction: 'asc'
                },
                incName: {
                    active: false,
                    direction: 'asc'
                },
                rawData: {
                    active: false,
                    direction: 'asc'
                },
                result: {
                    active: false,
                    direction: 'asc'
                },
                groupCode: {
                    active: false,
                    direction: 'asc'
                },
                groupName: {
                    active: false,
                    direction: 'asc'
                },
                partNumber: {
                    active: false,
                    direction: 'asc'
                },
                mfr: {
                    active: false,
                    direction: 'asc'
                },
                flag1: {
                    active: false,
                    direction: 'asc'
                },
                flag2: {
                    active: false,
                    direction: 'asc'
                },
                rawData2: {
                    active: false,
                    direction: 'asc'
                },
                rawData3: {
                    active: false,
                    direction: 'asc'
                },
                rawData4: {
                    active: false,
                    direction: 'asc'
                },
                link: {
                    active: false,
                    direction: 'asc'
                },
                cat: {
                    active: false,
                    direction: 'asc'
                },
                qc: {
                    active: false,
                    direction: 'asc'
                },
                status: {
                    active: false,
                    direction: 'asc'
                },
            },

            selectedRow: [],
            selectedDesc:'',
            selectedDescAfter:'',

            expandTable:false,
            showGlobalAttribute:false,

            searchNumber:'',
            searchInc:'',
            searchBefore:'',
            searchAfter:'',
            searchPartNumber:'',
            searchMfr:'',
            searchFlag1:'',
            searchStatus: '',
            searchAll:'',

            highlightText:'',
            highlightEnabled: false,
            selectedNumber: '',
            // currentPage: 1,
            openModalEdit: false,
            directEditData: null,

            openModalFlag: false,
            dataEditFlag:{},

            openModalMfr: false,
            dataEditMfr:{},

            openModalPn: false,
            dataEditPn:{},

            openModalInc: false,
            selectedInc: {},
            dataSelectInc:{},

            selectedCat: null
        }
    },
    methods: {
        async searchItems() {
            this.isSearching = true;

            this.$store.commit('SET_PAGE', 1);

            const payload = {
                searchNumber: this.searchNumber,
                searchInc: this.searchInc,
                searchBefore: this.searchBefore,
                searchAfter: this.searchAfter,
                searchPartNumber: this.searchPartNumber,
                searchMfr: this.searchMfr,
                searchFlag1: this.searchFlag1,
                searchStatus: this.searchStatus,
                searchAll: this.searchAll,
            };

            try {
                await this.$store.dispatch('newSearchItem', payload);
            } catch (error) {
                console.error('Error searching items', error);
            } finally {
                this.isSearching = false;
            }
        },
        handleScroll() {
            const scrollElement = this.$refs.scroll;
            if (Math.round(scrollElement.scrollTop + scrollElement.clientHeight) >= scrollElement.scrollHeight) {
                // Cek apakah ada pencarian yang dilakukan
                if (this.searchData.length > 0) {
                // Panggil action pencarian dengan parameter pencarian
                this.$store.dispatch('searchItems', {
                    searchNumber: this.searchNumber,
                    searchInc: this.searchInc,
                    searchBefore: this.searchBefore,
                    searchAfter: this.searchAfter,
                    searchPartNumber: this.searchPartNumber,
                    searchMfr: this.searchMfr,
                    searchFlag1: this.searchFlag1,
                    searchStatus: this.searchStatus,
                    searchAll: this.searchAll,
                });
                } else {
                this.$store.dispatch('fetchMaterialData');
                }
            }
            },
        toggleSort(column) {
            // console.log('kolom', column);
            this.sortOptions[column].active = !this.sortOptions[column].active;
            this.sortOptions[column].direction = this.sortOptions[column].direction === 'asc' ? 'desc' : 'asc';
            this.sortData(column);
        },
        sortData(column) {  
            const direction = this.sortOptions[column].direction === 'asc' ? 1 : -1;

            this.showData.sort((a, b) => {
                const valueA = (a[column] || '').toString().toLowerCase();
                const valueB = (b[column] || '').toString().toLowerCase();

                if (valueA < valueB) {
                return -1 * direction;
                }
                if (valueA > valueB) {
                return 1 * direction;
                }
                return 0;
            });
        },
        resetFilters() {
            this.searchNumber = '';
            this.searchInc = '';
            this.searchBefore = '';
            this.searchAfter = '';
            this.searchPartNumber = '';
            this.searchMfr = '';
            this.searchFlag1 = '';
            this.searchStatus = '';
            this.searchAll = '';

            this.searchItems();
        },
        showModal(data) {
            console.log('data--',data);
            this.directEditData = data; 
            console.log('drct edit data',this.directEditData);
            this.openModalEdit = true;
        },
        closeModal() {
            this.openModalEdit = false;
            
        },
        saveAndCloseModal(item){
            this.openModalEdit = false;
            // const itemData = this.searchData.length>0 ? this.searchData : this.itemData
            for (let data of this.showData) {
                if (data.id === item.id) {
                    data.result = item.result
                    data.attributeValue = item.attributeValue
                    // console.log('data', data);
                    // console.log('item', item);
                }
            }
        },
        handlePreview(data) {
            this.selectedDesc = data.rawData;
            this.selectedDescAfter = data.result;
            this.selectedNumber = data.materialNumber;

            const index = this.checkedRows.indexOf(data.id);

            if (index === -1) {
                this.selectedRow.push(data);
                this.checkedRows.push(data.id);
            } else {
                this.selectedRow.splice(index, 1);
                this.checkedRows.splice(index, 1);
            }

            this.$store.dispatch('updateSelectedRow', this.selectedRow);
            // console.log('selected Row: --', this.selectedRow);
        },
        updateSelectedStatus(status) {
            try{
                const listId = this.selectedRow.map(objek => objek.id);
                this.$store.dispatch('updateMaterialStatus', { listId: listId, status: status.toUpperCase() });
                this.$toast.success('Update Status Berhasil Tersimpan', {
                    duration: 2500,
                    position: 'top-right'
                });
                for (const data of this.selectedRow) {
                    data.status = status.toUpperCase();
                }
            }
            catch(error){
                this.$toast.error(`Gagal Update data Status, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        },
        updateCat() {
            console.log('test', this.selectedCat);
            const cat = this.selectedCat
            try{
                const listId = this.selectedRow.map(row => row.id);
                this.$store.dispatch('updateMaterialCat', { listId: listId, cat: cat.userId });
                this.$toast.success('Update Cataloguer Berhasil Tersimpan', {
                    duration: 2500,
                    position: 'top-right'
                });
                for (const data of this.selectedRow) {
                    data.cat = cat.userName
                }
                this.selectedCat = null
            }
            catch(error){
                this.$toast.error(`Gagal Update data Cataloguer, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        },
        updateQc(qc) {
            console.log('qc selcected', qc);
            console.log('qc', this.qcData);
            try{
                const listId = this.selectedRow.map(row => row.id);
                this.$store.dispatch('updateMaterialQc', { listId: listId, qc: qc });
                this.$toast.success('Update Qc Berhasil Tersimpan', {
                    duration: 2500,
                    position: 'top-right'
                });
                for (const data of this.selectedRow) {
                    data.qc = qc
                }
            }
            catch(error){
                this.$toast.error(`Gagal Update data Qc, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        },
        updateFlag(data){
            this.dataEditFlag = data
            this.openModalFlag = true;
        },
        closeModalFlag() {
            this.openModalFlag = false;
        },
        saveAndCloseModalFlag(flag) {
            this.openModalFlag = false;
            for (const data of this.showData) {
                if (data.id === flag.id) {
                    data.flag1 = flag.flag1
                    data.flag2 = flag.flag2
                }
            }
        },

        updateMfr(data){
            this.dataEditMfr = data
            this.openModalMfr = true;
        },
        closeModalMfr() {
            this.openModalMfr = false;
        },
        saveAndCloseModalMfr(mfr) {
            this.openModalMfr = false;
            // const itemData = this.searchData.length>0 ? this.searchData : this.itemData
            for (const data of this.showData) {
                if (data.id === mfr.id) {
                    data.mfr = mfr.mfr
                }
            }
        },
        updatePn(data){
            this.dataEditPn = data
            this.openModalPn = true;
        },
        closeModalPn() {
            this.openModalPn = false;
        },
        saveAndCloseModalPn(pn) {
            this.openModalPn = false;
            // const itemData = this.searchData.length>0 ? this.searchData : this.itemData
            for (const data of this.showData) {
                if (data.id === pn.id) {
                    data.partNumber = pn.partNumber
                }
            }
        },
        openInc(){
            this.openModalInc = true;
        },
        closeModalInc() {
            this.openModalInc = false;
            // window.location.reload();
        },
        saveAndCloseModalInc(data) {
            console.log('selectedIncItemList', data); // data: INC, INCName
            // this.updateInc(data.INC)
            this.updateInc(data)
            //
            //
            this.openModalInc = false;
            window.location.reload();
        },

        updateInc(inc) {
            try{
                const listId = this.selectedRow.map(objek => objek.id);
                this.$store.dispatch('updateInc', { listId: listId, inc: inc.INC });
                this.$toast.success('Update INC Berhasil Tersimpan', {
                    duration: 2500,
                    position: 'top-right'
                });
                
                if (inc.INC) {
                    for (const data of this.selectedRow) {
                        data.inc = inc.INC
                        data.incName = inc.INCName
                    }
                }
            }
            catch(error){
                this.$toast.error(`Gagal Update data INC, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        },

        resetSelectedRow() {
            this.selectedRow = [];
            this.checkedRows = [];
            this.$store.dispatch('updateSelectedRow', this.selectedRow);
        },

        selectAll() {
            let data = this.searchData.length>0 ? this.searchData : this.itemData
            if (this.selectedRow.length === data.length) {
                this.selectedRow = [];
                this.checkedRows = [];
            } else {
                this.selectedRow = data.slice();
                this.checkedRows = data.map(item => item.id);
            }
            this.$store.dispatch('updateSelectedRow', this.selectedRow);
        },

        editData() {
            // this.$router.push({ name: 'edit', query: { selectedRow: JSON.stringify(this.selectedRow) } });
            this.$router.push({ path: '/edit'});
            // this.$router.push({ 
            //     name: 'edit', 
            //     props: { 
            //         dataToEdit: this.selectedRow
            //     } 
            // });
        },

        highlightMissingWords(source, target) {
            const sourceWords = source?.split(/[ ,;]+/) || [];
            const targetWords = target?.split(/[ ,;]+/) || [];

            if (this.highlightEnabled && sourceWords.length > 0 && targetWords.length > 0) {
                const highlightedWords = sourceWords.slice();
                for (let i = 0; i < sourceWords.length; i++) {
                if (!targetWords.includes(sourceWords[i])) {
                    highlightedWords[i] = `<span class="text-slate-200 bg-red-400">${sourceWords[i]}</span>`;
                }
                }
                return highlightedWords.join(' ');
            } else {
                return source || ''; // Mengembalikan nilai source jika tidak ada pemrosesan yang diperlukan atau jika source adalah null/undefined
            }
        },

        highlightDescriptionAfter(data) {
            if (!data || !data.result || this.highlightText.trim() === "") {
                return data.result;
            }

            const keywords = this.highlightText?.toLowerCase().split(/\s+/).filter(keyword => keyword.trim() !== "");

            let descriptionAfter = data.result;

            keywords.forEach(keyword => {
                const regex = new RegExp(keyword, "gi");
                descriptionAfter = descriptionAfter.replace(regex, match => {
                return `<span class="text-slate-200 bg-red-400">${match}</span>`;
                });
            });

            return descriptionAfter;
        },
    },
    computed: {
        isDisabled() {
            // Check apakah ada item pada selectedRow yang memiliki nilai pada inc dan incName
            return this.selectedRow?.some(item => item.inc && item.incName);
        },
        isChecked() {
            const selectedIds = this.selectedRow.map(row => row.id);
            return id => selectedIds.includes(id);
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        globalAttributeData() {
            return this.$store.getters.getGlobalAttributes
        },
        itemData() {
            // return this.$store.getters.getItemData
            const userLogin = this.$props.userLogin;
            console.log('user login: ',userLogin);
            if (!userLogin) {
            return [];
            }

            const userRole = userLogin.role_id;
            // const userUsername = userLogin.username;

            if (userRole == 1) {
            return this.$store.getters.getItemData;

            } else if (userRole == 2) {
            return this.$store.getters.getItemData.filter(data => {
                return ['cat', 'qc'].includes(data.status?.toLowerCase());
            });

            } else if (userRole == 3) {
            return this.$store.getters.getItemData.filter(data => {
                return ['approved', 'qc'].includes(data.status?.toLowerCase());
            });

            } else if (userRole == 4) {
            return this.$store.getters.getItemData.filter(data => {
                return ['cat', 'named', 'new'].includes(data.status?.toLowerCase());
            });
            } else {
            return [];
            }
        },
        searchData() {
            const userLogin = this.$props.userLogin;

            if (!userLogin) {
                return [];
            }

            const userRole = userLogin.role_id;

            if (userRole == 1) {
                return this.$store.getters.getSearchData;
            } else if (userRole == 2) {
            return this.$store.getters.getSearchData.filter(data => {
                return ['cat', 'qc'].includes(data.status?.toLowerCase());
            });
            } else if (userRole == 3) {
            return this.$store.getters.getSearchData.filter(data => {
                return data.status?.toLowerCase() == "qc";
            });
            } else if (userRole == 4) {
            return this.$store.getters.getSearchData.filter(data => {
                return data.cat == ['cat', 'named', 'new'].includes(data.status?.toLowerCase());
            });
            } else {
            return [];
            }  
        },
        cataloguerData() {
           return this.$store.getters.getUserCataloguer
        },
        qcData() {
           return this.$store.getters.getUserQc
        },

        totalItems() {
            return this.filteredDataAll.length;
        },

        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },

        showData() {
            if(this.$store.getters.isSearchingData){
                return this.searchData
                // return this.searchData.length>0 ? this.searchData : this.itemData
            } else {
                return this.itemData
            }
        },
    },
    mounted(){
        this.$store.dispatch('fetchUserData')
        this.$store.dispatch('fetchMaterialData')
        this.$store.dispatch('fetchAllData')
        this.$store.dispatch('fetchIncData')
        this.$store.dispatch('fetchAttributeData')
        this.$store.dispatch('fetchGroupData')
        this.$store.dispatch('fetchGlobalAttributeData')
        console.log('itemdata',this.itemData);
    }
}
</script>