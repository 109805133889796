<template>
  <div v-if="showConfirmationModal" class="fixed inset-0 z-[9999999999999] overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div class="h-64 w-96 bg-white flex flex-col p-3">
        <p class="text-md font-bold text-gray-700 text-center">Apakah Anda yakin?</p>
        <div class="flex justify-center gap-4 mt-4">
          <button @click="cancelConfirmation" class="border py-2 px-4 bg-red-600 text-white font-semibold">Tidak</button>
          <button @click="confirmSave" class="border py-2 px-4 bg-green-600 text-white font-semibold">Ya</button>
        </div>
      </div>
    </div>
  <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div @click.stop class="h-4/5 w-3/4 bg-white flex flex-col p-3">
    
      <div class="flex flex-col h-full w-full gap-2">
        <div class="">
          <p class="text-md font-bold text-gray-700 text-center">Pilih Inc</p>
        </div>
        <div class="w-full flex flex-col justify-betweem">
          <div class="flex justify-center gap-5 mb-4">
            <input type="text" v-model="searchCode" placeholder="Search INC Code" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
            <input type="text" v-model="searchName" placeholder="Search INC Name" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
          </div>

          <div class="flex w-full xl:max-h-[300px] 2xl:max-h-[450px] max-h-[280px] overflow-auto">
            <table class="border-2 w-full border-slate-950">
              <thead>
                <tr class="text-left">
                  <th>INC Code</th>
                  <th>INC Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredIncName" :key="item.id">
                  <td class="border-y border-gray-400">{{ item.INC }}</td>
                  <td class="border-y border-gray-400">{{ item.INCName }}</td>
                  <td class="border-y border-gray-400">
                    <button @click="selectInc(item)" class="px-2 py-1 bg-blue-500 text-white rounded-md">Select</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div class="flex flex-col justify-center gap-4">          
          <div v-if="localData" class="flex justify-center items-center h-1/2 w-full border border-slate-500 bg-slate-200">
            Selected Inc: {{localData.INC}} - {{localData.INCName}}
          </div>
          <div v-else class="flex justify-center items-center h-1/4 w-full border border-slate-500">
            Selected Inc: -
          </div>
          <div class="w-full flex justify-center">
            <button @click="saveAndClose" class="border py-4 w-3/4 bg-green-600 text-white font-semibold">Save</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      localData: {},
      searchCode: '',
      searchName: '',
      selectedIncName: '',
      showConfirmationModal: false,
    };
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains('bg-gray-900')) {
        this.$emit("resetSelectedRow");
        this.$emit('closeModalInc');
      }
    },
    // saveAndClose() {
    //   this.$emit("resetSelectedRow");
    //   this.$emit('saveAndCloseModalInc', this.localData);
    // },
    showConfirmation() {
      this.showConfirmationModal = true;
    },
    // Metode untuk menutup modal konfirmasi dan membatalkan penyimpanan
    cancelConfirmation() {
      this.showConfirmationModal = false;
    },
    // Metode untuk menutup modal dan menyimpan data
    closeModalAndSave() {
      this.$emit('saveAndCloseModalInc', this.localData);
      this.$emit("resetSelectedRow");
    },
    // Metode untuk menutup modal dan menampilkan modal konfirmasi sebelum menyimpan data
    saveAndClose() {
      this.showConfirmation();
    },
    // Metode untuk menyimpan data setelah konfirmasi
    confirmSave() {
      this.closeModalAndSave();
      this.showConfirmationModal = false; // Tutup modal konfirmasi setelah simpan
    },
    selectInc(inc) {
      // this.data.selectedInc = inc;
      this.selectedIncName = inc.INC;
      this.localData = inc
    },
  },
  computed: {
    incData() {
      return this.$store.getters.getIncData;
    },

    filteredInc() {
      if (!this.searchCode) {
                return this.incData;
            }
            let searchTerms = this.searchCode.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredInc = this.incData;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredInc = filteredInc.filter(data => {
                        return keywords.every(keyword => {
                            return data.INC.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredInc = filteredInc.filter(data => {
                        return keywords.some(keyword => {
                            return data.INC.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredInc = filteredInc.filter(data => {
                        return data.INC.toLowerCase().includes(term);
                    });
                }
            });

            return filteredInc;
    },
    filteredIncName() {
      if (!this.searchName) {
                return this.filteredInc;
            }
            let searchTerms = this.searchName.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredInc = this.incData;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredInc = filteredInc.filter(data => {
                        return keywords.every(keyword => {
                            return data.INCName.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredInc = filteredInc.filter(data => {
                        return keywords.some(keyword => {
                            return data.INCName.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredInc = filteredInc.filter(data => {
                        return data.INCName.toLowerCase().includes(term);
                    });
                }
            });

            return filteredInc;
    }
  },
  mounted() {
    this.localData = { ...this.data };
    console.log(this.localData);
  }
};
</script>