<template>
  <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div @click.stop class="h-4/5 w-5/6 bg-white flex flex-col p-3">
    
      <div class="flex flex-col h-full w-full gap-2">
        <div class="">
          <p class="text-md font-bold text-gray-700 text-center">Group</p>
        </div>
        <div class="w-full flex flex-col justify-betweem">
          <div class="flex justify-center gap-5 mb-4">
            <input type="text" v-model="searchGroupCode" placeholder="Search Group Code" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
            <input type="text" v-model="searchGroupName" placeholder="Search Group Name" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
          </div>

          <div class="flex w-full xl:max-h-[350px] 2xl:max-h-[450px] max-h-[280px] overflow-auto">
            <table class="border-2 w-full border-slate-950">
              <thead>
                <tr class="text-left">
                  <th>Group Code</th>
                  <th>Group Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredGroupName" :key="item.id">
                  <td class="border-y border-gray-400">{{ item.groupCode }}</td>
                  <td class="border-y border-gray-400">{{ item.groupName }}</td>
                  <td class="border-y border-gray-400 flex flex-row flex-wrap gap-2">
                    <!-- <div class=""> -->
                        <button @click="editGroup(item)" class="px-2 py-1 bg-yellow-500 text-white rounded-sm">Edit</button>
                        <button @click="deleteGroup(item)" class="px-2 py-1 bg-red-500 text-white rounded-sm">Delete</button>
                    <!-- </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modalEditGroup v-if="openEditGroup" :group="dataEditGroup" @close="closeModalEdit"/>
  </div>
</template>

<script>
import ModalEditGroup from '@/utils/masterdata/group/ModalEditGroup.vue';
export default {
    components: {
        ModalEditGroup
    },
  data() {
    return {
      searchGroupCode: '',
      searchGroupName: '',
      dataEditGroup: null,
      openEditGroup: false
    };
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains('bg-gray-900')) {
        this.$emit('close'); //close
      }
    },
    closeModalEdit() {
        this.openEditGroup = false
    },
    editGroup(item) {
        console.log(item);
        this.dataEditGroup = item
        this.openEditGroup = true
    },
    deleteGroup(item) {
        try {
            console.log('item id',item.id);
            this.$store.dispatch('deleteGroup', item.id)
            this.$toast.success('Berhasil Menghapus Group', {
                    duration: 2500,
                    position: 'top-right'
                });
        } catch (error) {
            this.$toast.error(`Gagal Menghapus data Group, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
        }
    }
  },
  computed: {
    groupData() {
      return this.$store.getters.getGroupData;
    },

    filteredGroupCode() {
      if (!this.searchGroupCode) {
                return this.groupData;
            }
            let searchTerms = this.searchGroupCode.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredGroupCode = this.groupData;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredGroupCode = filteredGroupCode.filter(data => {
                        return keywords.every(keyword => {
                            return data.groupCode.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredGroupCode = filteredGroupCode.filter(data => {
                        return keywords.some(keyword => {
                            return data.groupCode.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredGroupCode = filteredGroupCode.filter(data => {
                        return data.groupCode.toLowerCase().includes(term);
                    });
                }
            });

            return filteredGroupCode;
    },
    filteredGroupName() {
      if (!this.searchGroupName) {
                return this.filteredGroupCode;
            }
            let searchTerms = this.searchGroupName.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredGroupName = this.filteredGroupCode;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredGroupName = filteredGroupName.filter(data => {
                        return keywords.every(keyword => {
                            return data.groupName.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredGroupName = filteredGroupName.filter(data => {
                        return keywords.some(keyword => {
                            return data.groupName.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredGroupName = filteredGroupName.filter(data => {
                        return data.groupName.toLowerCase().includes(term);
                    });
                }
            });

            return filteredGroupName;
    }
  },
  mounted() {
    this.$store.dispatch('fetchGroupData')
    console.log('group data',this.groupData);
  }
};
</script>