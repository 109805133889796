<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-fit w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col py-2 h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Edit Group</p>
                </div>
                <hr/>

                <form class="max-w-sm mx-auto w-full" @submit.prevent="updateGroup">
                    <div>
                        <div class="mb-5 w-full">
                            <label for="group_code" class="block mb-2 text-sm font-medium text-gray-900">Group Code</label>
                            <input type="text" id="group_code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Group Code" v-model="localGroupData.groupCode" required>
                        </div>
                        <div class="mb-5 w-full">
                            <label for="group_name" class="block mb-2 text-sm font-medium text-gray-900">Group Name</label>
                            <input type="text" id="group_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Group Name" v-model="localGroupData.groupName" required>
                        </div>
                    </div>
            
                    <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    props: {
        group: Object
    },
    data() {
        return {
            localGroupData: {},
            // groupCode: '',
            // groupName: '',
        };
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                this.$emit('close');
            }
        },
        updateGroup() {
            try{
                const group = {
                    id: this.localGroupData.id,
                    groupCode: this.localGroupData.groupCode,
                    groupName: this.localGroupData.groupName,
                }
                // const listId = this.selectedRow.map(objek => objek.id);
                this.$store.dispatch('updateMasterGroup', { group });
                this.$toast.success('Group Berhasil diUpdate', {
                    duration: 2500,
                    position: 'top-right'
                });
            }
            catch(error){
                this.$toast.error(`Gagal Update Group, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        }
    },
    mounted() {
         this.localGroupData = { ...this.group };
         console.log(this.localGroupData);
    }
}
</script>