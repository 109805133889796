<template>
  <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div @click.stop class="h-4/5 w-5/6 bg-white flex flex-col p-3">
    
      <div class="flex flex-col h-full w-full gap-2">
        <div class="">
          <p class="text-md font-bold text-gray-700 text-center">Attribute</p>
        </div>
        <div class="w-full flex flex-col justify-betweem">
          <div class="flex justify-center gap-5 mb-4">
            <input type="text" v-model="searchIncCode" placeholder="Search INC Code" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
            <input type="text" v-model="searchAttributeCode" placeholder="Search Attribute Code" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
            <input type="text" v-model="searchAttributeName" placeholder="Search Attribute Name" class="px-2 py-1 border border-gray-500 rounded-sm w-96">
          </div>

          <div class="flex w-full xl:max-h-[350px] 2xl:max-h-[450px] max-h-[280px] overflow-auto">
            <table class="border-2 w-full border-slate-950">
              <thead>
                <tr class="text-left">
                  <th>INC Code</th>
                  <th>Attribute Code</th>
                  <th>Attribute Name</th>
                  <th>Sequence</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredAttributeName" :key="item.id">
                  <td class="border-y border-gray-400">{{ item.inc }}</td>
                  <td class="border-y border-gray-400">{{ item.attributeCode }}</td>
                  <td class="border-y border-gray-400">{{ item.attributeName }}</td>
                  <td class="border-y border-gray-400">{{ item.sequence }}</td>
                  <td class="border-y border-gray-400 flex flex-row flex-wrap gap-2">
                        <button @click="editAttribute(item)" class="px-2 py-1 bg-yellow-500 text-white rounded-sm">Edit</button>
                        <button @click="deleteAttribute(item)" class="px-2 py-1 bg-red-500 text-white rounded-sm">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modalEditAttribute v-if="openEditAttribute" :attribute="dataEditAttribute" @close="closeModalEdit"/>
  </div>
</template>

<script>
import ModalEditAttribute from '@/utils/masterdata/attribute/ModalEditAttribute.vue';
export default {
    components: {
        ModalEditAttribute
    },
  data() {
    return {
      searchIncCode: '',
      searchAttributeCode: '',
      searchAttributeName: '',
      dataEditAttribute: null,
      openEditAttribute: false
    };
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains('bg-gray-900')) {
        this.$emit('close'); //close
      }
    },
    closeModalEdit() {
        this.openEditAttribute = false
    },
    editAttribute(item) {
        console.log(item);
        this.dataEditAttribute = item
        this.openEditAttribute = true
    },
    deleteAttribute(item) {
        try {
            console.log('item id',item.id);
            this.$store.dispatch('deleteAttribute', item.id)
            this.$toast.success('Berhasil Menghapus Attribute', {
                    duration: 2500,
                    position: 'top-right'
                });
        } catch (error) {
            this.$toast.error(`Gagal Menghapus data Attribute, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
        }
    }
  },
  computed: {
    attributesData() {
      const data = this.$store.getters.getAttributes
      return data.sort((a, b) => {
        if (a.inc < b.inc) return -1;
                if (a.inc > b.inc) return 1;
                // If INC Code is same, sort by sequence
                return a.sequence - b.sequence;
      });
      // return this.$store.getters.getAttributes;
    },

    filteredInc() {
      if (!this.searchIncCode) {
                return this.attributesData;
            }
            let searchTerms = this.searchIncCode.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredAttribute = this.attributesData;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredAttribute = filteredAttribute.filter(data => {
                        return keywords.every(keyword => {
                            return data.inc.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredAttribute = filteredAttribute.filter(data => {
                        return keywords.some(keyword => {
                            return data.inc.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredAttribute = filteredAttribute.filter(data => {
                        return data.inc.toLowerCase().includes(term);
                    });
                }
            });

            return filteredAttribute;
    },
    filteredAttributeCode() {
      if (!this.searchAttributeCode) {
                return this.filteredInc;
            }
            let searchTerms = this.searchAttributeCode.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredAttribute = this.filteredInc;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredAttribute = filteredAttribute.filter(data => {
                        return keywords.every(keyword => {
                            return data.attributeCode.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredAttribute = filteredAttribute.filter(data => {
                        return keywords.some(keyword => {
                            return data.attributeCode.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredAttribute = filteredAttribute.filter(data => {
                        return data.attributeCode.toLowerCase().includes(term);
                    });
                }
            });

            return filteredAttribute;
    },
    filteredAttributeName() {
      if (!this.searchAttributeName) {
                return this.filteredAttributeCode;
            }
            let searchTerms = this.searchAttributeName.toLowerCase().split(/\s+|\|\|/).filter(term => term.trim() !== '');

            let filteredAttribute = this.filteredAttributeCode;

            searchTerms.forEach(term => {
                if (term.includes("||")) {
                    const keywords = term.split("||").map(keyword => keyword.trim());
                    filteredAttribute = filteredAttribute.filter(data => {
                        return keywords.every(keyword => {
                            return data.attributeName.toLowerCase().includes(keyword);
                        });
                    });
                } else if (term.includes("|")) {
                    const keywords = term.split("|").map(keyword => keyword.trim());
                    filteredAttribute = filteredAttribute.filter(data => {
                        return keywords.some(keyword => {
                            return data.attributeName.toLowerCase().includes(keyword);
                        });
                    });
                } else {
                    filteredAttribute = filteredAttribute.filter(data => {
                        return data.attributeName.toLowerCase().includes(term);
                    });
                }
            });

            return filteredAttribute;
    }
  },
  mounted() {
    this.$store.dispatch('fetchAttributeData')
  }
};
</script>