<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-fit w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col py-2 h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Upload Material Group</p>
                </div>
                <hr/>

                <form class="max-w-sm mx-auto" @submit.prevent="addGroup">
                    <div>
                        <div class="mb-5">
                            <label for="attr_code" class="block mb-2 text-sm font-medium text-gray-900">Pilih File</label>
                            <input type="file" @change="handleFileUpload" id="attr_code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Group Code" required>
                        </div>
                    </div>
            
                    <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>        
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            groupCode: '',
            groupName: '',
            sequence: '',
            file:null,
            excelData: [],
        };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.file = file;
                this.readFile(file);
            }
        },
        readFile(file) {
            const reader = new FileReader();

            reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            this.excelData = excelData;
            this.file = file;
            console.log('excel data: ', this.excelData);
            };

            reader.readAsArrayBuffer(file);

        },
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                // this.$store.dispatch('updateMaterialData', this.localFlagData)
                this.$emit('close');
            }
        },
        addGroup() {
            try{
                // const groupData = {
                //     groupCode: this.groupCode,
                //     groupName: this.groupName
                // }
                // const listId = this.selectedRow.map(objek => objek.id);
                this.$store.dispatch('bulkInsertGroup', { file: this.file });
                this.$toast.success('Material Group Baru Berhasil Tersimpan', {
                    duration: 2500,
                    position: 'top-right'
                });
            }
            catch(error){
                this.$toast.error(`Gagal Menyimpan Material Group Baru, ${error}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        }
    },
}
</script>