<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-2/4 w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col justify-between h-1/2 w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Edit Item</p>
                </div>
                <div class="w-full flex flex-col">
                    <label class="font-semibold" for="">Part Number: </label>
                    <textarea rows="4" type="text" class="outline-none border border-red-500 text-sm" v-model="localData.partNumber"/>
                </div>
                <div class="w-full flex justify-center">
                    <button @click="saveAndClose" class="border w-1/4 bg-green-600 text-white font-semibold">Save</button>
                </div> 
            </div>
        </div>        
    </div>
</template>

<script>
export default {
    props: {
        data: Object
    },
    data() {
        return {
            localData: {}
        };
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                this.$emit('closeModalPn');
            }
        },
        saveAndClose() {
            try {
                    this.$store.dispatch('updateMaterialData', this.localData)
                    this.$toast.success('Update Part Number Berhasil Tersimpan', {
                        duration: 2500,
                        position: 'top-right'
                    });
                    this.$emit('saveAndCloseModalPn', this.localData);
                } catch (error) {
                    this.$toast.error(`Gagal Update Part Number Status, ${error}`, {
                        duration: 2500,
                        position: 'top-right'
                    });
                }
        }
    },
    mounted() {
        this.localData = { ...this.data };
        console.log('data -- ', this.localData);
    }
}
</script>
